import React, { useState, useRef, useEffect } from 'react'
import '../styles/CreateNft.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa'


import { FaCloudUploadAlt } from 'react-icons/fa'

import { FaPlus } from 'react-icons/fa6'


// History Cart

function CreateNftPage() {
  // DropDown

  const dropdownRef = useRef(null)
  const subjectRef = useRef(null)


  // Click in other and close
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenArt(false)
      }
      if (subjectRef.current && !subjectRef.current.contains(event.target)) {
        setIsOpenSubject(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  // -- Art --
  const [isOpenArt, setIsOpenArt] = useState(false)
  const toggleDropdownArt = () => setIsOpenArt(!isOpenArt)

  // -- Subject --
  const [isOpenSubject, setIsOpenSubject] = useState(false)
  const toggleDropdownSubject = () => setIsOpenSubject(!isOpenSubject)

  
  return (
    <div>
      <section className='create-nft-section'>
        {/* <!--setting-container--> */}
        <div className='create-nft-container container'>
          {/* <!--background-light--> */}
          <div className='top-background-light background-lights'></div>
          {/* <!--main-title--> */}
          <div className='create-nft-main-title-wrapper'>
            <h1 className='create-nft-heading-primary'>Create an NFT</h1>
            <p className='header-text'>
              Once your item is minted you will not be able to change any of its
              information
            </p>
          </div>
          <div className='create-nft-content-wrapper'>
            {/* <!--upload--> */}
            <div class='upload-nft-box'>
              {/* <!--content-wrapper--> */}
              <div className='upload-nft-box-content-wrapper'>
                {/* <!--icon--> */}
                <div className='icon-upload-create-wrapper'>
                  <FaCloudUploadAlt className='fa-solid fa-cloud-arrow-up upload-nft-icon' />
                </div>
                {/* <!--input--> */}
                <input
                  type='file'
                  className='upload-input'
                  id='upload-nft'
                  name='upload-nft'
                  hidden
                />
                {/* <!--upload-button--> */}
                <label for='upload-nft' className='upload-nft-button'>
                  Upload media
                </label>
                {/* <!--size-text--> */}
                <p className='upload-text upload-size-text'>Max size: 50MG</p>
                <p className='upload-text upload-type-text'>JPG,PNG,GIF,SVG</p>
              </div>
            </div>
            {/* <!--upload-form--> */}
            <form action='#' className='upload-form'>
              <div className='form-content-wrapper'>
                {/* <!--create-collection-button--> */}
                {/* <!--title--> */}
                <h3 className='upload-form-group-title'>Collection*</h3>
                <a href='#' className='create-collection-button'>
                  {/* <!--shape--> */}
                  <div className='create-collection-shap'>
                    <FaPlus className='fa-solid fa-plus create-collection-shap-icon' />
                  </div>
                  {/* <!--text--> */}
                  <p className='create-collection-text'>
                    create a new collection
                  </p>
                </a>
                {/* <!--NFT-name--> */}
                {/* <!--title--> */}
                <h3 className='upload-form-group-title'>Name*</h3>
                <div className='upload-form-group'>
                  {/* <!--input--> */}
                  <input
                    className='upload-form-input'
                    type='text'
                    name='name'
                    placeholder='Name your NFT'
                    required
                  />
                </div>
                {/* <!--title--> */}
                <h3 className='upload-form-group-title'>Supply*</h3>
                <div className='upload-form-group'>
                  {/* <!--input--> */}
                  <input
                    className='upload-form-input'
                    type='number'
                    name='supply'
                    value='1'
                    required
                  />
                </div>
                {/* <!--title--> */}
                <h3 className='upload-form-group-title'>Price*</h3>
                <div className='upload-form-group'>
                  {/* <!--input--> */}
                  <input
                    className='upload-form-input'
                    type='text'
                    name='price'
                    placeholder='Polygon(Enter the amoutn)'
                    required
                  />
                </div>
                <h3 className='upload-form-group-title'>Description</h3>
                <div className='upload-form-group description-box-wrapper'>
                  {/* <!--input--> */}
                  <textarea
                    name='description'
                    id='description'
                    placeholder='Enter the description'
                    className='description-box'
                  ></textarea>
                </div>
                {/* <!--category---> */}
                {/* <!--title--> */}
                <h3 className='upload-form-group-title'>Category</h3>
                <div className='category-wrapper'>
                  {/* <!--text--> */}
                  <p className='upload-form-text'>
                    category define the style of your artworks. They appear as
                    filters inside your collection page and are also listed out
                    inside your item page
                  </p>
                  {/* <!--drop-buttons--> */}
                  <div className='drop-button-wrapper'>
                    {/* <!--dorop-botton------> */}
                    <div className='custom-dropdown' ref={dropdownRef}>
                      <div
                        className='dropdown-header'
                        onClick={toggleDropdownArt}
                      >
                        <span className='selected'>Art Style</span>
                        {isOpenArt ? <FaChevronUp /> : <FaChevronDown />}
                      </div>
                      {isOpenArt && (
                        <ul className='dropdown-list'>
                          {/* <!--item--> */}
                          <li>Modern</li>
                          {/* <!--item--> */}
                          <li>Impressionism</li>
                          {/* <!--item--> */}
                          <li>Surrealism</li>
                          {/* <!--active-item---> */}
                          <li>Photography</li>
                          {/* <!--item--> */}
                          <li>Digital Art</li>
                          {/* <!--item--> */}
                          <li>Calligraphy</li>
                        </ul>
                      )}
                    </div>

                    {/* <!--dorop-botton------> */}
                    <div className='custom-dropdown' ref={subjectRef}>
                      <div
                        className='dropdown-header'
                        onClick={toggleDropdownSubject}
                      >
                        <span className='selected'>Subject</span>
                        {isOpenSubject ? <FaChevronUp /> : <FaChevronDown />}
                      </div>
                      {isOpenSubject && (
                        <ul class='dropdown-list'>
                          {/* <!--item--> */}
                          <li>Faces & Portraits</li>
                          {/* <!--item--> */}
                          <li>Landscapes</li>
                          {/* <!--item--> */}
                          <li>Abstract</li>
                          {/* <!--item--> */}
                          <li>Animals</li>
                          {/* <!--item--> */}
                          <li>Figurative</li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--create-button-wrapper--> */}
              <div className='create-btn-wrapper'>
                <a href='#'>
                  <button type='submit' className='create-btn'>
                    Create
                  </button>
                </a>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CreateNftPage
