import React, { useState, useRef } from 'react'
import '../styles/Create.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'

import { IoCreateOutline } from 'react-icons/io5'
import { IoMdCreate } from 'react-icons/io'

import { FaArrowRight } from 'react-icons/fa'

// image
import img1 from '../assets/images/create-bg-img-1.jpg'
import img2 from '../assets/images/create-bg-img-2.jpg'
import img3 from '../assets/images/create-bg-img-3.jpg'
import img4 from '../assets/images/create-bg-img-4.jpg'
import logo from '../assets/images/create-diption-logo.png'

// History Cart

function CreatePage() {
  // navigate
  let navigate = useNavigate()

  const handleProfileIcon = () => {
    navigate('/login')
  }

  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='#' className='logo-link'>
                DIPTION
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile' onClick={handleProfileIcon}>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/* <!--=========main==============--> */}
      <main className='main'>
        {/* <!--============create-section================--> */}
        <section className='create-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--main-wrapper--> */}
            <div className='create-content-wrapper'>
              {/* <!--image-wrapper--> */}
              <div className='create-background'>
                {/* <!--img--> */}
                <img
                  src={img1}
                  alt='background-img'
                  className='create-background-content background-img-1'
                />
                {/* <!--img--> */}
                <img
                  src={img2}
                  alt='background-img'
                  className='create-background-content background-img-2'
                />
                {/* <!--img--> */}
                <img
                  src={img3}
                  alt='background-img'
                  className='create-background-content background-img-3'
                />
                {/* <!--img--> */}
                <img
                  src={img4}
                  alt='background-img'
                  className='create-background-content background-img-4'
                />
              </div>
              {/* <!--create-box--> */}
              <div className='create-box'>
                {/* <!--logo-wrapper--> */}
                <div className='logo-wrapper'>
                  {/* <!--logo---> */}
                  <img src={logo} alt='logo' className='diption-logo-img'></img>
                </div>
                {/* <!--create-content--> */}
                <div className='create-box-content'>
                  {/* <!--title--> */}
                  <h1 className='heading-primary'>
                    <span className='heading-primary-main'>Create</span>
                    <span className='heading-primary-sub'>
                      Dropping an NFT refers to the process of making it
                      available for individuals to claim, purchase, or acquire
                      in some way.
                    </span>
                  </h1>
                  {/* <!--options-wrapper--> */}
                  <div className='create-options-wrapper'>
                    {/* <!--create-collection--> */}
                    <a href='/create-collection' className='create-option-link'>
                      <div className='create-collection create-option'>
                        {/* <!--hole-text-wrapper--> */}
                        <div className='create-option-content-wrapper'>
                          {/* <!---icon-title-wrapper--> */}
                          <div className='create-icon-title-wrapper'>
                            {/* <!--icon--> */}
                            <IoCreateOutline className='fa-solid fa-diagram-project create-title-icon' />
                            <h2 className='heading-secondary'>
                              Create a collection
                            </h2>
                          </div>
                          {/* <!--text--> */}
                          <p className='create-text mobile-size-text-create-text'>
                            {' '}
                            Launch your NFT collection for mint{' '}
                          </p>
                        </div>
                        <FaArrowRight className=' fa-solid fa-arrow-right create-option-icon' />
                      </div>
                    </a>
                    {/* <!--upload-NFT--> */}
                    <a href='/create-nft' className='create-option-link'>
                      <div className='create-collection create-option'>
                        {/* <!--hole-text-wrapper--> */}
                        <div className='create-option-content-wrapper'>
                          {/* <!---icon-title-wrapper--> */}
                          <div className='create-icon-title-wrapper'>
                            {/* <!--icon--> */}
                            <IoMdCreate className='fa-solid fa-diagram-project create-title-icon' />
                            <h2 className='heading-secondary'>Mint an NFT</h2>
                          </div>
                          {/* <!--text--> */}
                          <p className='create-text mobile-size-text-create-text'>
                            Create and mint NFTs directly to your wallet
                          </p>
                        </div>
                        <FaArrowRight className=' fa-solid fa-arrow-right create-option-icon' />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default CreatePage
