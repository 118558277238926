import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import Shop from './pages/Shop'
import Recommendation from './pages/Recommendation'
import History from './pages/History'
import Favorite from './pages/Favorite'
import ArtistProfile from './pages/ArtistProfile'
import Profile from './pages/Profile'
import Collection from './pages/Collection'
import NftDetails from './pages/NftDetails'
import Create from './pages/Create'
import CollectionCreate from './pages/CreateCollection'
import NftCreate from './pages/CreateNft'
import ContactUS from './pages/ContactUs'
import AboutUs from './pages/AboutUs'
import Plans from './pages/Plans'
import PodcastArticle from './pages/PodcastArticle'

import Test from './pages/Test'

import { ThirdwebProvider } from '@thirdweb-dev/react'

function App() {
  return (
    <ThirdwebProvider>
      <Router>
        <div className='App'>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/shop' element={<Shop />} />
            <Route path='/recommendation' element={<Recommendation />} />
            <Route path='/history' element={<History />} />
            <Route path='/favorite' element={<Favorite />} />
            <Route path='/artist-profile' element={<ArtistProfile />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/collection' element={<Collection />} />
            <Route path='/nft-details' element={<NftDetails />} />
            <Route path='/create' element={<Create />} />
            <Route path='/create-collection' element={<CollectionCreate />} />
            <Route path='/create-nft' element={<NftCreate />} />
            <Route path='/contact-us' element={<ContactUS />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/plans' element={<Plans />} />
            <Route path='/podcast-article' element={<PodcastArticle />} />
            <Route path='/test' element={<Test />} />
          </Routes>
        </div>
      </Router>
    </ThirdwebProvider>
  )
}

export default App
