import React, { useState, useRef } from 'react'
import '../styles/Collection.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'
import { FaHeart, FaRegHeart } from 'react-icons/fa'

import { IoMdExit } from 'react-icons/io'

import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

// image

import ShopPic1 from '../assets/images/nft-1.jpg'
import ShopPic2 from '../assets/images/nft-2.jpg'
import ShopPic3 from '../assets/images/nft-3.jpg'
import ShopPic4 from '../assets/images/shop-img.jpg'
import ShopPic5 from '../assets/images/nft-5.jpg'
import ShopPic6 from '../assets/images/nft-6.jpg'
import ShopPic7 from '../assets/images/nft-7.jpg'
import ShopPic8 from '../assets/images/nft-8.jpg'
import logo from '../assets/images/diption-logo-2.jpg'

// History Cart

function CollectionPage() {
  // navigate
  let navigate = useNavigate()

  const handleProfileIcon = () => {
    navigate('/login')
  }

  // Option

  const [activeOption, setActiveOption] = useState('top')

  // DropDown
  // -- Art Styles --
  const [showMenuArt, setShowMenuArt] = useState(true)
  const [checkedStateArt, setCheckedStateArt] = useState({
    modern: false,
    impressionism: false,
    surrealism: false,
    photography: false,
    digitalArt: false,
    calligraphy: false,
  })

  const toggleMenuArt = () => {
    setShowMenuArt(!showMenuArt)
  }

  const handleCheckboxArtChange = (event) => {
    setCheckedStateArt({
      ...checkedStateArt,
      [event.target.name]: event.target.checked,
    })
  }

  // -- Subject --

  const [showDropdownSubject, setShowDropdownSubject] = useState(false)
  const [checkedStatesSubject, setCheckedStatesSubject] = useState({
    facesAndPortraits: false,
    landscapes: false,
    abstract: false,
    animals: false,
    figurative: false,
  })

  const toggleMenuSubject = () => setShowDropdownSubject(!showDropdownSubject)

  const handleCheckboxSubjectChange = (event) => {
    setCheckedStatesSubject({
      ...checkedStatesSubject,
      [event.target.name]: event.target.checked,
    })
  }

  // -- Price--

  const [showDropdownPrice, setShowDropdownPrice] = useState(false)
  const [priceRangeChecks, setPriceRangeChecks] = useState({
    under100: false,
    from100To500: false,
    from500To1000: false,
    above1000: false,
  })

  const toggleMenuPrice = () => setShowDropdownPrice(!showDropdownPrice)

  const handleCheckboxPriceChange = (event) => {
    const { name, checked } = event.target
    setPriceRangeChecks((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  // -- Chains --

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [checkboxes, setCheckboxes] = useState({
    Ethereum: false,
    Polygon: false,
    Bep20: false,
    Other: false,
  })

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }))
  }

  const products = [
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic1,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic2,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic3,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic4,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic5,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic6,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic7,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic8,
    },
  ]

  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile' onClick={handleProfileIcon}>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/* <!--=====header-section=============================================--> */}
      <header className='collection-header'>
        {/*     <!--header-banner-section------> */}
        <section className='header-banner-section'>
          {/* <!--setting-container--> */}
          <div className='header-banner-image-contaienr collection-container'>
            {/* <!--profile-box-----> */}
            <div className='collection-profile-image'></div>
          </div>
          {/* <!--profile-deatails-----> */}
          <div className='collection-profile-deatails'>
            {/* <!--setting-container--> */}
            <div className='collection-profile-deatails-container collection-container'>
              <div className='collection-profile-deatails-content-wrapper'>
                {/* <!--titile-and-create-and-item-wrapper----> */}
                <div className='collection-profile-title-and-create-and-item-wrapper'>
                  {/*   <!--title--> */}
                  <div className='collection-profile-title-wrapper'>
                    <h2 className='collection-profile-title'>Monsters Club</h2>
                  </div>
                  {/* <!--create-and-item--> */}
                  <div className='collection-profile-create-and-item-wrapper'>
                    <p className='create-time'>
                      <span className='total-volume-color margin-right '>
                        Created
                      </span>
                      <span className='collection-profile-span'>June 2024</span>
                    </p>
                    <p className='item-number'>
                      <span className='total-volume-color margin-right'>
                        items
                      </span>
                      <span className='collection-profile-span'>82</span>
                    </p>
                  </div>
                </div>
                {/* <!--collection-total-and-floor-wrapper----> */}
                <div className='collection-profile-total-and-floor-wrapper'>
                  <div className='totla-volume-wrapper'>
                    <p className='total-volume  '>
                      <span className='total-and-floor-price'>0.4607 ETH</span>
                      <span className='total-volume-color'>Total volume</span>
                    </p>
                  </div>

                  <div className='floor-price-wrapper'>
                    <p className='floor-price'>
                      <span className='total-and-floor-price'>0.4607 ETH</span>
                      <span className='total-volume-color'>Floor price</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--caption-and-buttons-section--> */}
        <section className='caption-and-buttons-section'>
          {/* <!--setting-container--> */}
          <div className='caption-and-price-container collection-container'>
            {/* <!--caption-buttons-wrapper-----> */}
            <div className='caption-and-buttons-wrapper'>
              {/* <!--caption--> */}
              <div className='collection-profile-caption-wrapper'>
                <p className='collection-profile-caption'>
                  Welcome to the Monsters Club<span className='dot'>...</span>
                  <span className='see-more'>See more</span>
                  <span className='rest-of-the-profil-caption'>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Aperiam, totam?
                  </span>
                </p>
              </div>
              {/* <!--buttons--> */}
              {/* <!--like-share-buttons-----------> */}
              <div className='collection-like-share-buttons-wrapper'>
                {/* Like */}
                <button className='profile-like-button'>
                  <FaRegHeart className='profile-like-icon profile-button-icon' />
                </button>
                {/* Share */}
                <button className='profile-share-button'>
                  <IoMdExit className='profile-share-icon profile-button-icon' />
                </button>
              </div>
            </div>
          </div>
        </section>
      </header>
      {/* <!--===========main-section============--> */}
      <main className='main'>
        {/* <!--=======search-sort-section=========--> */}
        <section className='search-sort-section'>
          {/* <!--setting-container----> */}
          <div className='search-srot-section-container collection-container'>
            <div className='search-and-sort-wrapper'>
              {/* <!--search-------> */}
              <form className='search-wrapper'>
                <button type='submit' className='search-btn'>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
                <input
                  type='search'
                  className='search-input'
                  placeholder='Search'
                />
              </form>
              {/* <!--sort-btn--> */}
              <div className='custom-dropdown'>
                <div className='dropdown-header' onClick={toggleMenuArt}>
                  <span className='selected'>Art Style</span>
                  {showMenuArt ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                {showMenuArt && (
                  <ul className='dropdown-list'>
                    {/* <!--item--> */}
                    <li>Newest</li>
                    {/* <!--item--> */}
                    <li>Oldest</li>
                  </ul>
                )}
              </div>
            </div>
            {/* <!--Under-line--> */}
            <span className='section-under-line'></span>
          </div>
        </section>
        {/* <!--===============collection-filter-and-boxes-wrapper================--> */}
        <section className='collection-filters-and-boxes-section'>
          {/* <!--====setting-collection-container=================--> */}
          <div className='collection-filters-and-boxes-container collection-container'>
            <div className='collection-filters-and-boxes-wrapper'>
              {/*  <!--=================collection-drop-btn-section==================----> */}
              <section className='collection-drop-button-section'>
                <div className='collection-drop-button-wrapper'>
                  {/* art Styles */}
                  <div className='dropdown-btn-shop'>
                    <div className='select-shop' onClick={toggleMenuArt}>
                      <span className='selected'>Art Styles</span>
                      <FontAwesomeIcon icon={faChevronDown} className='cart' />
                    </div>
                    {showMenuArt && (
                      <ul className='drop-menu-shop  art-style-drop-menu'>
                        <li>
                          <input
                            type='checkbox'
                            id='modern-check'
                            name='modern'
                            checked={checkedStateArt.modern}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='modern-check'>Modern</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='impressionism-check'
                            name='impressionism'
                            checked={checkedStateArt.impressionism}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='impressionism-check'>
                            Impressionism
                          </label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='surrealism-check'
                            name='surrealism'
                            checked={checkedStateArt.surrealism}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='surrealism-check'>Surrealism</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='photography-check'
                            name='photography'
                            checked={checkedStateArt.photography}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='photography-check'>Photography</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='digital-art-check'
                            name='digitalArt'
                            checked={checkedStateArt.digitalArt}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='digital-art-check'>Digital Art</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='calligraphy-check'
                            name='calligraphy'
                            checked={checkedStateArt.calligraphy}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='calligraphy-check'>Calligraphy</label>
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* Subject */}
                  <div className='dropdown-btn-shop'>
                    <div className='select-shop' onClick={toggleMenuSubject}>
                      <span className='selected'>Subject</span>
                      <FontAwesomeIcon icon={faChevronDown} className='cart' />
                    </div>
                    {showDropdownSubject && (
                      <ul className='drop-menu-shop subject-drop-menu'>
                        <li>
                          <input
                            type='checkbox'
                            id='faces-and-portraits-check'
                            name='facesAndPortraits'
                            checked={checkedStateArt.facesAndPortraits}
                            onChange={handleCheckboxSubjectChange}
                          />
                          <label htmlFor='faces-and-portraits-check'>
                            Faces & Portraits
                          </label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='landscapes-check'
                            name='landscapes'
                            checked={checkedStateArt.landscapes}
                            onChange={handleCheckboxSubjectChange}
                          />
                          <label htmlFor='landscapes-check'>Landscapes</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='Abstract-check'
                            name='abstract'
                            checked={checkedStateArt.abstract}
                            onChange={handleCheckboxSubjectChange}
                          />
                          <label htmlFor='Abstract-check'>Abstract</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='Animals-check'
                            name='animals'
                            checked={checkedStateArt.animals}
                            onChange={handleCheckboxSubjectChange}
                          />
                          <label htmlFor='Animals-check'>Animals</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='figurative-check'
                            name='figurative'
                            checked={checkedStateArt.figurative}
                            onChange={handleCheckboxSubjectChange}
                          />
                          <label htmlFor='figurative-check'>Figurative</label>
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* Price */}
                  <div className='dropdown-btn-shop'>
                    <div className='select-shop' onClick={toggleMenuPrice}>
                      <span className='selected'>Price</span>
                      <FontAwesomeIcon icon={faChevronDown} className='cart' />
                    </div>
                    {showDropdownPrice && (
                      <ul className='drop-menu-shop price-drop-menu'>
                        <li>
                          <input
                            type='checkbox'
                            id='under-100-check'
                            name='under100'
                            checked={priceRangeChecks.under100}
                            onChange={handleCheckboxPriceChange}
                          />
                          <label htmlFor='under-100-check'>Under $100</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='from-100-to-500-check'
                            name='from100To500'
                            checked={priceRangeChecks.from100To500}
                            onChange={handleCheckboxPriceChange}
                          />
                          <label htmlFor='from-100-to-500-check'>
                            $100 to $500
                          </label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='from-500-to-1000-check'
                            name='from500To1000'
                            checked={priceRangeChecks.from500To1000}
                            onChange={handleCheckboxPriceChange}
                          />
                          <label htmlFor='from-500-to-1000-check'>
                            $500 to $1,000
                          </label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='above-1000-check'
                            name='above1000'
                            checked={priceRangeChecks.above1000}
                            onChange={handleCheckboxPriceChange}
                          />
                          <label htmlFor='above-1000-check'>Above $1,000</label>
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* Chains */}
                  <div className='dropdown-btn-shop'>
                    <div className='select-shop' onClick={toggleDropdown}>
                      <span className='selected'>Chains</span>
                      <FontAwesomeIcon icon={faChevronDown} className='cart' />
                    </div>
                    {isDropdownOpen && (
                      <ul className='drop-menu-shop chains-drop-menu'>
                        {Object.entries(checkboxes).map(([key, value]) => (
                          <li key={key}>
                            <input
                              type='checkbox'
                              id={`${key}-check`}
                              name={key}
                              checked={value}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor={`${key}-check`}>
                              {key.charAt(0).toUpperCase() +
                                key.slice(1).replace('-', ' ')}
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </section>

              {/* <!--=================collection-boxes=========----------> */}
              <section className='collection-boxs-section'>
                <div className='collection-box-wrapper'>
                  {/* <!---background-light------------------> */}
                  {/*<!--item-----------> */}

                  {products.map((product, index) => (
                    <a
                      key={index}
                      href='/'
                      className='collection-product-item-link'
                    >
                      <div className='collection-product-box'>
                        {/* <!--img------> */}
                        <div class='collection-product-box-img-wrapper'>
                          <img src={product.image} alt='img' />
                        </div>
                        {/* <!--caption------> */}
                        <div class='collection-product-box-caption'>
                          {/* <!--caption-deatails-----> */}
                          <div class='caption-deatails'>
                            {/* <!--item-name----------> */}
                            <strong class='collection-product-name'>
                              {product.name}
                            </strong>

                            {/* <!--price-----> */}

                            <p class='collection-product-price'>
                              {product.price}
                            </p>
                          </div>

                          {/* <!--creator------> */}

                          <p class='collection-product-creator'>
                            {product.creator}
                          </p>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </section>
      </main>
      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default CollectionPage
