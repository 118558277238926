import React, { useState, useRef } from 'react'
import '../styles/Favorite.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

// image

import ShopPic1 from '../assets/images/nft-1.jpg'
import ShopPic2 from '../assets/images/nft-2.jpg'
import ShopPic3 from '../assets/images/nft-3.jpg'
import ShopPic4 from '../assets/images/shop-img.jpg'
import ShopPic5 from '../assets/images/nft-5.jpg'
import ShopPic6 from '../assets/images/nft-6.jpg'
import ShopPic7 from '../assets/images/nft-7.jpg'
import ShopPic8 from '../assets/images/nft-8.jpg'
import ShopPic20 from '../assets/images/artist-profile-11.jpg'
import ShopPic21 from '../assets/images/artist-profile-12.jpg'
import ShopPic22 from '../assets/images/artist-profile-13.jpg'
import ShopPic23 from '../assets/images/artist-profile-14.jpg'
import ShopPic24 from '../assets/images/artist-profile-15.jpg'
import ShopPic25 from '../assets/images/artist-profile-16.jpg'
import ShopPic26 from '../assets/images/category-section-img-1.png'
import ShopPic27 from '../assets/images/category-section-img-2.png'
import ShopPic28 from '../assets/images/category-section-img-3.png'
import ShopPic29 from '../assets/images/categ-1.jpg'
import ShopPic30 from '../assets/images/categ-2.jpg'
import ShopPic31 from '../assets/images/categ-3.jpg'
import ShopPic32 from '../assets/images/art-1.jpg'
import ShopPic33 from '../assets/images/art-2.jpg'
import ShopPic34 from '../assets/images/art-3.jpg'
import logo from '../assets/images/diption-logo-2.jpg'

// History Cart

const TopProducts = [
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic34,
    date: '14/Feb/2024',
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic33,
    date: '14/Feb/2024',
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic32,
    date: '14/Feb/2024',
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic29,
    date: '14/Feb/2024',
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic27,
    date: '14/Feb/2024',
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic28,
    date: '14/Feb/2024',
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic30,
    date: '14/Feb/2024',
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic31,
    date: '14/Feb/2024',
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic7,
    date: '14/Feb/2024',
  },
]

function FavoritePage() {
  const [activeOption, setActiveOption] = useState('all')

  const handleOptionClick = (option) => {
    setActiveOption(option)
  }

  // navigate
  let navigate = useNavigate()

  const handleProfileIcon = () => {
    navigate('/login')
  }

  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile' onClick={handleProfileIcon}>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/* <!--=====favorite-title-and-button-section========--> */}
      <section className='history-title-button-section'>
        {/* <!--setting-container--------> */}
        <div className='history-title-button-container history-container'>
          {/* <!--history-titlle-and-button------------------> */}
          <div className='history-title-button-content-wrapper'>
            {/* <!--history-title--------------> */}
            <h2 className='history-title'>Favorite</h2>
          </div>
        </div>
      </section>
      {/* <!--======favorite-boxes-section==============--> */}
      {/* <!--======favorite-options-section(Top-Popular-Trending)=======--> */}
      <section className='favorite-options-and-button-section'>
        <div className='options-and-button-favorite-section-container favorite-container'>
          <div className='favorite-option-and-button-wrapper'>
            <div className='favorite-option-content-wrapper'>
              <div className='favorite-options-wrapper'>
                <button
                  className={`favorite-option-button all-section-btn ${
                    activeOption === 'all' ? 'active' : ''
                  }`}
                  onClick={() => handleOptionClick('all')}
                >
                  All items
                </button>
                <button
                  className={`favorite-option-button collections-section-btn ${
                    activeOption === 'collections' ? 'active' : ''
                  }`}
                  onClick={() => handleOptionClick('collections')}
                >
                  Collection
                </button>
                <button
                  className={`favorite-option-button artist-profiles-section-btn ${
                    activeOption === 'artist-profiles' ? 'active' : ''
                  }`}
                  onClick={() => handleOptionClick('artist-profiles')}
                >
                  Artist profiles
                </button>
                <div
                  className={`active-box active-box-start-${activeOption}-section`}
                ></div>
              </div>
            </div>
          </div>
          <span className='favorite-options-section-underline'></span>
        </div>
      </section>
      {/* <!--==========favorite-Allitems-Section===============--> */}
      <section className='all-items-section favorite-sections'>
        <div className='favorite-all-items-container favorite-container'>
          {/* <!---background-light------------------> */}
          <div className=' '>
            <div className='all-items-box-wrapper'>
              <div className='all-items-box-wrapper'>
                {TopProducts.map((product, index) => (
                  <Link
                    key={index}
                    to='/'
                    className='all-items-product-item-link'
                  >
                    <div class='all-items-product-box'>
                      {/* <!--img------> */}
                      <div className='all-items-product-box-img-wrapper'>
                        <img src={product.image} alt={product.name} />
                      </div>
                      {/* <!--caption------> */}
                      <div className='all-items-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong class='all-items-product-name'>
                            {product.name}
                          </strong>

                          {/* <!--price-----> */}

                          <p className='all-items-product-price'>
                            {product.price}
                          </p>
                        </div>

                        {/* <!--creator------> */}

                        <p className='all-items-product-creator'>
                          {product.creator}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default FavoritePage
