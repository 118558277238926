import React, { useState, useRef } from 'react'
import '../styles/NftDetails.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'

import NFTimg from '../assets/images/nft-img-1.jpg'
import { FaEthereum } from 'react-icons/fa6'

import { FaArrowRight, FaRegHeart } from 'react-icons/fa'
import { IoMdExit, IoIosPricetag } from 'react-icons/io'

// image

import ShopPic1 from '../assets/images/nft-1.jpg'
import ShopPic2 from '../assets/images/nft-2.jpg'
import ShopPic3 from '../assets/images/nft-3.jpg'
import ShopPic4 from '../assets/images/shop-img.jpg'
import ShopPic5 from '../assets/images/nft-5.jpg'
import ShopPic6 from '../assets/images/nft-6.jpg'
import ShopPic7 from '../assets/images/nft-7.jpg'
import ShopPic8 from '../assets/images/nft-8.jpg'
import logo from '../assets/images/diption-logo-2.jpg'

// History Cart

function NftDetailsPage() {
  // navigate
  let navigate = useNavigate()

  const handleProfileIcon = () => {
    navigate('/login')
  }

  // Option

  const products = [
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic1,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic2,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic3,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic4,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic5,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic6,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic7,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic8,
    },
  ]

  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile' onClick={handleProfileIcon}>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/*<!--================main-section=======================--> */}
      <main className='main'>
        {/*<!--========nft-section==========--> */}
        <section className='nft-section'>
          {/* <!--setting-container------> */}
          <div className='nft-section-container nft-container'>
            {/* <!--wrapper---------> */}
            <div className='nft-content-wrapper'>
              {/*<!--image-box--> */}
              <div className='nft-image'>
                {/*  <!--img--> */}
                <img src={NFTimg} alt='nft' className='nft-image-content' />
                {/* <!--nft-caption--> */}
                <div className='nft-caption'>
                  {/* <!--icon--> */}
                  <div className='nft-crypto-icon'>
                    <FaEthereum className='fa-brands fa-ethereum nft-crypto-icon-content' />
                  </div>
                  {/* <!--full-and-like-wrapper----> */}
                  <div class='nft-image-buttons'>
                    {/* <!--full--> */}
                    {/* Like */}
                    <button className='nft-image-button'>
                      <FaRegHeart className='fa-solid fa-expand nft-image-full-icon nft-image-button-icon' />
                    </button>
                    {/* Share */}
                    <button className='nft-image-button'>
                      <IoMdExit className='fa-regular fa-heart nft-image-like-icon nft-image-button-icon' />
                    </button>
                  </div>
                </div>
              </div>
              {/* <!--info-box--> */}
              <div className='nft-info'>
                {/* <!--name-and-buttons-wrapper--> */}
                <div className='nft-name-creator-and-buttons-wrapper'>
                  {/* <!--name-and-craetor--> */}
                  <div className='nft-name-wrapper'>
                    {/* <!--name--> */}
                    <h1 className='nft-name'>Cute Monsters</h1>
                    {/* <!--creator--> */}
                    <p className='nft-creator'>
                      <span className='nft-creator-span'>Owned by </span>Trever
                      Jones
                    </p>
                  </div>
                  {/* <!--button--> */}
                  <div className='nft-button-wrapper'>
                    {/* Like */}
                    <button className='nft-btn'>
                      <FaRegHeart className='fa-solid fa-flag nft-btn-icon' />
                    </button>
                    {/* Share */}
                    <button className='nft-btn'>
                      <IoMdExit className='fa-solid fa-arrow-up-from-bracket nft-btn-icon' />
                    </button>
                  </div>
                </div>
                {/* <!--nft-info-box--> */}
                {/* <!--description--> */}
                <div className='nft-info-box nft-description-box'>
                  {/* <!--title--> */}
                  <div className='nft-info-box-title'>
                    {/* <!--icon--> */}
                    <i className='fa-solid fa-file-contract nft-info-box-icon'></i>
                    <h3 className='nft-info-box-title-text'>Description</h3>
                  </div>
                  {/* <!--text--> */}
                  <div className='nft-info-box-text nft-description-content'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Rem, praesentium.
                  </div>
                </div>
                {/* <!--nft-info-box--> */}
                {/* <!--details--> */}
                <div className='nft-info-box nft-deatails-box'>
                  {/* <!--title--> */}
                  <div className='nft-info-box-title'>
                    {/* <!--icon--> */}
                    <i className='fa-solid fa-file-signature nft-info-box-icon'></i>
                    <h3 className='nft-info-box-title-text'>Deatails</h3>
                  </div>
                  {/* <!--text--> */}
                  <div className='nft-info-box-text nft-deatails-content'>
                    {/* <!--deatails-list---> */}
                    <ul className='nft-deatails-list'>
                      {/* <!--deatails-item---> */}
                      <li className='nft-deatails-item'>
                        <h5 className='nft-deatail-name'>Contract Adress</h5>
                        <p className='nft-deatail-info'>
                          <a
                            href='/'
                            class='nft-deatail-info-link'
                            target='_blank'
                          >
                            0x2a20FbCf095eb347edA8C107Bc3ddFF165E0f22b
                          </a>
                        </p>
                      </li>
                      {/* <!--deatails-item---> */}
                      <li className='nft-deatails-item'>
                        <h5 className='nft-deatail-name'>Token Standrad</h5>
                        <p className='nft-deatail-info'>ERC-721</p>
                      </li>
                      {/* <!--deatails-item---> */}
                      <li className='nft-deatails-item'>
                        <h5 className='nft-deatail-name'>Chain</h5>
                        <p className='nft-deatail-info'>Ethereum</p>
                      </li>
                      {/* <!--deatails-item---> */}
                      <li className='nft-deatails-item'>
                        <h5 className='nft-deatail-name'>Last Updated</h5>
                        <p className='nft-deatail-info'>4 hours ago</p>
                      </li>
                      {/* <!--deatails-item---> */}
                      <li className='nft-deatails-item'>
                        <h5 className='nft-deatail-name'>Creator Earnings</h5>
                        <p className='nft-deatail-info'>4%</p>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!--nft-offer-box--> */}
                <div className='nft-offer-box'>
                  {/* <!--offer---> */}
                  <div className='nft-offer'>
                    {/* <!--icon--> */}
                    <IoIosPricetag className='fa-solid fa-flag nft-btn-icon' />
                    {/* <!--text--> */}
                    <p className='nft-offer-text'>Price</p>
                  </div>
                  {/* <!--price--> */}
                  <div className='nft-offer-price'>
                    {/* <!--text--> */}
                    <p className='nft-offer-text'>0.035 ETH</p>
                    {/* <!--icon--> */}
                    <FaEthereum className='fa-solid fa-arrow-up-from-bracket nft-btn-icon' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<!--=======more-nft-=========--> */}
        <section className='nft-more-section'>
          {/* <!--setting-container--> */}
          <div className='nft-more-container nft-container'>
            {/* <!--nft-info-box--> */}
            <div className='nft-info-box nft-more-box'>
              {/* <!--title--> */}
              <div className='nft-info-box-title nft-info-box-more-title'>
                {/* <!--icon--> */}
                <i class='fa-solid fa-clone nft-info-box-icon'></i>
                <h3 class='nft-info-box-title-text'>
                  More from this Collection
                </h3>
              </div>
              {/* <!--nft-more-content--> */}
              <div className='nft-info-box-content'>
                {/* <!--box-wrapper----> */}
                <div className='slider'>
                  {/* <!--box--> */}
                  {products.map((product, index) => (
                    <a key={index} href='/' className='nft-box-link'>
                      <div className='nft-box'>
                        {/* <!--box-image--> */}
                        <img
                          src={product.image}
                          alt='nft-1'
                          className='nft-box-img'
                        />
                        {/* <!--box-description--> */}
                        <div className='nft-box-description'>
                          {/* <!--name-price-wrapper--> */}
                          <div className='nft-name-price-wrapper'>
                            {/* <!--nft-box-name--> */}
                            <h4 className='nft-box-name'>{product.name}</h4>
                            {/* <!--nft-box-price--> */}
                            <p className='nft-box-price'>{product.price}</p>
                          </div>
                          {/* <!--creator-name--> */}
                          <p className='nft-box-crator-name'>
                            {product.creator}
                          </p>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            {/* <!--view-collection--> */}
            <div className='view-collection-btn-wrapper'>
              <a href='/' className='view-collection-btn'>
                View Collection
                <FaArrowRight className='fa-solid fa-arrow-right' />
              </a>
            </div>
          </div>
        </section>
      </main>
      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default NftDetailsPage
