import { React } from 'react'
import '../styles/AboutUs.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'

// image
import img1 from '../assets/images/about-us-img-1.jpg'
import img2 from '../assets/images/about-us-img-2.jpg'
import img3 from '../assets/images/about-us-img-3.jpg'
import img4 from '../assets/images/about-us-img-4.jpg'
import img5 from '../assets/images/block-chain-logo.png'
import img6 from '../assets/images/people-logo.png'
import logo from '../assets/images/diption-logo-2.jpg'

// History Cart

function ContactUsPage() {




  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile'>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>

      {/* <!--===========header=============--> */}
      <header className='header'>
        <h1 className='about-heading-primary'>
          <span className='heading-primary-main'>About Us</span>
          <span className='heading-primary-sub'>Digital art marketing</span>
        </h1>
      </header>

      {/* <!--===========main=============--> */}

      <main className='main'>
        {/* <!--=====welcome-section==========--> */}
        <section className='welcome-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--main-wrapper--> */}
            <div className='welcome-content-wrapper'>
              {/* <!--image-boxes-wrapper--> */}
              <div className='welcome-img-box-wrapper'>
                {/* <!--left-side---> */}
                <div className='left-img-wrapper'>
                  {/* <!--img-box--> */}
                  <div className='welcome-img-box'>
                    <img src={img4} alt='welcome-img' className='welcome-img' />
                  </div>
                  {/* <!--img-box--> */}
                  <div className='welcome-img-box'>
                    <img src={img1} alt='welcome-img' className='welcome-img' />
                  </div>
                </div>
                {/* <!--right-side--> */}
                <div className='right-img-wrapper'>
                  {/* <!--img-box--> */}
                  <div className='welcome-img-box'>
                    <img src={img2} alt='welcome-img' className='welcome-img' />
                  </div>
                  {/* <!--img-box--> */}
                  <div className='welcome-img-box'>
                    <img src={img3} alt='welcome-img' className='welcome-img' />
                  </div>
                </div>
              </div>
              {/* <!--text-wrapper--> */}
              <div className='welcome-text-wrapper'>
                {/* <!--title--> */}
                <h2 className='heading-secondary'>Welcome to Diption</h2>
                {/* <!--text--> */}
                <p className='paragraph'>
                  the premier destination for exploring, collecting, and trading
                  digital assets in the form of Non-Fungible Tokens (NFTs). We
                  are passionate about the intersection of technology, art, and
                  creativity, and our platform is designed to empower artists,
                  collectors, and enthusiasts from around the world.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <!--=====our-mission-section==========--> */}
        <section className='our-mission-section'>
          {/* <!--setting-contaienr--> */}
          <div className='container'>
            {/* <!--our-mission-box--> */}
            <div className='our-mission-box'>
              {/* <!--title-box--> */}
              <div className='our-mission-title-box'>
                <h3 className='our-mission-title'>Our Mission</h3>
              </div>
              <p className='paragraph our-mission-text'>
                At Diption, our mission is to revolutionize the way we perceive,
                own, and interact with digital art and other unque digital
                assets. We believe that NFTs have the power to unlock new
                possibilities for artist to showcase their talent, collectors to
                dicover extraordinary pieces, and enthusiasts to immerse
                themselves in the ever-evolving world of digital creativity
              </p>
            </div>
          </div>
        </section>
        {/* <!--=====why-us-section==========--> */}
        <section className='why-us-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            <div className='why-us-content-wrapper'>
              {/* <!--title-box--> */}
              <div className='why-us-title-box'>
                {/* <!--title--> */}
                <h3 className='why-us-title'>Why Us !</h3>
                {/* <!--arrows--> */}
                <span className='arrows'></span>
              </div>
              {/* <!--why-us-boxes--> */}
              <div className='why-us-boxes-wrapper'>
                {/* <!--box--> */}
                <div className='why-us-box'>
                  {/* <!--title-wrapper--> */}
                  <div className='why-us-box-title-wrapper'>
                    {/* <!--icon--> */}
                    <img
                      src={img5}
                      alt='block-chain-logo'
                      className='block-chain-logo why-us-logo'
                    />
                    {/* <!--title--> */}
                    <h4 className='why-us-box-title'>Blockchain Technology</h4>
                  </div>
                  {/* <!--text--> */}
                  <p className='why-us-text'>
                    We leverage the power of blockchain technology to ensure
                    authenticity, provenance, and transparency for every digital
                    asset on our platform.
                  </p>
                </div>
                {/* <!--box--> */}
                <div className='why-us-box'>
                  {/* <!--title-wrapper--> */}
                  <div className='why-us-box-title-wrapper'>
                    {/* <!--icon--> */}
                    <img
                      src={img6}
                      alt='block-chain-logo'
                      className='block-chain-logo why-us-logo'
                    />
                    {/* <!--title--> */}
                    <h4 className='why-us-box-title'>Vibrant Community</h4>
                  </div>
                  {/* <!--text--> */}
                  <p className='why-us-text'>
                    If you're an established artist looking to showcase your
                    work or a passionate collector searching for the next gem to
                    add to your collection, Diption is the place to be.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--=====join-us-section==========--> */}
        <section className='join-us-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            <div className='join-us-content-wrapper'>
              {/* <!--text-wrapper--> */}
              <div className='join-us-text-wrapper'>
                {/* <!--text--> */}
                <p className='paragraph'>
                  Join us on this groundbreaking journey as we redefine the art
                  market, empower creators, and unlock the potential of digital
                  ownership. Together, let's shape the future of art and
                  collectibles with the transformative power of NFTs.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default ContactUsPage
