import React, { useState, useRef } from 'react'
import '../styles/Plans.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'
import { FaRegCheckCircle } from 'react-icons/fa'
import logo from '../assets/images/diption-logo-2.jpg'


// image

// History Cart

function PlansPage() {
  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile'>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/* <!--===========main=============--> */}
      <main className='main'>
        {/* <!--background--> */}
        <div className='plan-main-circle plan-background-circle'>
          <div className='plan-second-circle plan-background-circle'>
            <div className='plan-third-circle plan-background-circle'>
              <div className='plan-background-light-main'></div>
            </div>
          </div>
        </div>
        {/* <!--=========card-sectino===========--> */}
        <section className='card-section'>
          {/* <!--setting-container--> */}
          <div className='card-section-container container'>
            {/* <!--title--> */}
            <div className='title-wrapper'>
              <h1 className='heading-primary'>Pricing</h1>
              <p className='heading-paragraph'>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Earum,
                tenetur.
              </p>
            </div>

            {/* <!--sort-buttons-wrapper--> */}
            <div className='sort-button-wrapper'>
              <button className='sort-btn monthly-btn sort-active-btn'>
                Monthly
              </button>
              <button className='sort-btn yearly-btn'>Yearly</button>
            </div>

            {/* <!--card-wrapper--> */}
            <div className='card-wrapper'>
              {/* <!--card--> */}
              <div className='card'>
                {/* <!--plan-type--> */}
                <div className='card-type'>
                  <p className='card-type-text'>Basic Plan</p>
                </div>
                {/* <!--price-and-lenth--> */}
                <div className='card-price-lenth-wrapper'>
                  <p className='card-price-lenth'>
                    <span className='price'>$49</span>/
                    <span className='lenth'>Month</span>
                  </p>
                </div>
                {/* <!--card-description--> */}
                <p className='card-description'>
                  Lorem, ipsum dolor sit amet consectetur
                </p>
                {/* <!--plan-options--> */}
                <div className='card-options-wrapper'>
                  <ul className='card-option-list'>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      <i className='fa-regular fa-circle-check card-option-icon'></i>
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                  </ul>
                </div>
                {/* <!--start-btn--> */}
                <a href='#' className='card-main-button'>
                  {' '}
                  Star This Plan{' '}
                </a>
              </div>

              {/* <!--card--> */}
              <div className='card'>
                {/* <!--plan-type--> */}
                <div className='card-type'>
                  <p className='card-type-text'>Popular Plan</p>
                </div>
                {/* <!--price-and-lenth--> */}
                <div className='card-price-lenth-wrapper'>
                  <p className='card-price-lenth'>
                    <span className='price'>$49</span>/
                    <span className='lenth'>Month</span>
                  </p>
                </div>
                {/* <!--card-description--> */}
                <p className='card-description'>
                  Lorem, ipsum dolor sit amet consectetur
                </p>
                {/* <!--plan-options--> */}
                <div className='card-options-wrapper'>
                  <ul className='card-option-list'>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                  </ul>
                </div>
                {/* <!--start-btn--> */}
                <a
                  href='#'
                  className='card-main-button card-main-button-active'
                >
                  Star This Plan
                </a>
              </div>

              {/* <!--card--> */}
              <div className='card'>
                {/* <!--plan-type--> */}
                <div className='card-type'>
                  <p className='card-type-text'>Pro Plan</p>
                </div>
                {/* <!--price-and-lenth--> */}
                <div className='card-price-lenth-wrapper'>
                  <p className='card-price-lenth'>
                    <span className='price'>$49</span>/
                    <span className='lenth'>Month</span>
                  </p>
                </div>
                {/* <!--card-description--> */}
                <p className='card-description'>
                  Lorem, ipsum dolor sit amet consectetur
                </p>
                {/* <!--plan-options--> */}
                <div className='card-options-wrapper'>
                  <ul className='card-option-list'>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                    <li className='card-option-item'>
                      <FaRegCheckCircle className='fa-regular fa-circle-check card-option-icon' />
                      Lorem ipsum dolor sit amet.
                    </li>
                  </ul>
                </div>
                {/* <!--start-btn--> */}
                <a href='#' className='card-main-button'>
                  {' '}
                  Star This Plan{' '}
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default PlansPage
