import React, { useState, useRef } from 'react'
import '../styles/ContactUs.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'

// image

import phonelogo from '../assets/images/phone-logo.png'
import emaillogo from '../assets/images/email-logo.png'
import adresslogo from '../assets/images/address-logo.png'
import logo from '../assets/images/diption-logo-2.jpg'

// History Cart

function ContactUsPage() {
  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile'>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/* <!--===========header=============--> */}
      <header className='header'>
        {/* <!--wrapper--> */}
        <div className='header-content-wrapper'>
          <h1 className='contact-heading-primary'>Contact US</h1>
          {/* <!--email-warpper--> */}
          <div className='header-info-wrapper'>
            {/* <!--email-logo--> */}
            <img
              src={emaillogo}
              alt='email-logo'
              className='header-info-logo'
            />
            {/* <!--email--> */}
            <a href='/' className='header-info  header-info-link'>
              Diption.group@gmail.com
            </a>
          </div>
          {/* <!--phone-warpper--> */}
          <div className='header-info-wrapper'>
            {/* <!--phone-logo--> */}
            <img
              src={phonelogo}
              alt='phone-logo'
              className='header-info-logo'
            />
            {/* <!--phone--> */}
            <a href='/' className='header-info header-info-link'>
              +12505550199
            </a>
          </div>
          {/* <!--address-warpper--> */}
          <div className='header-info-wrapper'>
            {/* <!--address-logo--> */}
            <img
              src={adresslogo}
              alt='address-logo'
              className='header-info-logo'
            />
            {/* <!--address--> */}
            <address className='header-info'>
              358 Industry Drive.Toronto, ON M4C-1V7 Canada
            </address>
          </div>
        </div>
      </header>
      {/*<!--===========main=============--> */}

      <main className='main'>
        {/* <!--===============get-in-touch-section============--> */}
        <section className='get-in-touch-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--form-wrapper--> */}
            <div className='contact-form-wrapper'>
              <form action='#' className='get-in-touch-form'>
                {/* <!--title--> */}
                <h3 className='heading-tertiary'>Get in Touch</h3>
                {/* <!--first-last-name-wrapper--> */}
                <div className='form-first-last-name-wrapper'>
                  {/* <!--form-group--> */}
                  <div className='form-group form-group-name'>
                    {/* <!--title--> */}
                    <h4 className='form-group-title'>First Name</h4>
                    <div className='input-wrapper'>
                      {/* <!--input--> */}
                      <input
                        type='text'
                        name='name'
                        className='form-input'
                        placeholder='Enter your First name'
                      />
                    </div>
                  </div>
                  {/* <!--form-group--> */}
                  <div className='form-group form-group-name'>
                    {/* <!--title--> */}
                    <h4 className='form-group-title'>Last Name</h4>
                    <div className='input-wrapper'>
                      {/* <!--input--> */}
                      <input
                        type='text'
                        name='name'
                        className='form-input'
                        placeholder='Enter your Last name'
                      />
                    </div>
                  </div>
                </div>
                {/* <!--form-group--> */}
                <div className='form-group'>
                  {/* <!--title--> */}
                  <h4 className='form-group-title'>Gmail</h4>
                  {/* <!--input-wrapper--> */}
                  <div className='input-wrapper'>
                    {/* <!--input--> */}
                    <input
                      type='email'
                      name='email'
                      className='form-input'
                      placeholder='Enter your gmail'
                    />
                  </div>
                </div>
                {/* <!--form-group--> */}
                <div className='form-group'>
                  {/* <!--title--> */}
                  <h4 className='form-group-title'>Message</h4>
                  {/* <!--input-wrapper--> */}
                  <div className='message-wrapper'>
                    {/* <!--input--> */}
                    <textarea
                      name='message'
                      className='message-text-area'
                      placeholder='Enter your message'
                    ></textarea>
                  </div>
                </div>
                {/* <!--btn-wrapper--> */}
                <div className='form-button-wrapper'>
                  {/* <!--submit-btn--> */}
                  <button type='submit' className='form-btn'>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
        {/* <!--===============contanct-us-section============--> */}
        <section className='contact-us-section'>
          {/* <!--main-wrapper--> */}
          <div className='contact-us-content-wrapper'>
            {/* <!--box--> */}
            <div className='contanct-us-box'>
              {/* <!--img--> */}
              <img
                src={adresslogo}
                className='contanct-us-box-logo'
                alt='address-logo'
              />
              {/* <!--title--> */}
              <h5 className='heading-fifth'>Visit us</h5>
              {/* <!--text--> */}
              <p className='paragraph'>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
                autem voluptatum sunt ratione, similique dolor.
              </p>
              {/* <!--info--> */}
              <p className='contact-us-info contact-us-info-address'>
                358 Industry Drive.Toronto, ON M4C-1V7 Canada
              </p>
            </div>
            {/* <!--box--> */}
            <div className='contanct-us-box'>
              {/* <!--img--> */}
              <img
                src={phonelogo}
                className='contanct-us-box-logo'
                alt='address-logo'
              />
              {/* <!--title--> */}
              <h5 className='heading-fifth'>Call us</h5>
              {/* <!--text--> */}
              <p className='paragraph'>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
                autem voluptatum sunt ratione, similique dolor.
              </p>
              {/* <!--info--> */}
              <a href='#' className='contact-us-info contact-us-info-link'>
                +12505550199
              </a>
            </div>
            {/* <!--box--> */}
            <div className='contanct-us-box'>
              {/* <!--img--> */}
              <img
                src={adresslogo}
                className='contanct-us-box-logo'
                alt='address-logo'
              />
              {/* <!--title--> */}
              <h5 className='heading-fifth'>Contact us</h5>
              {/* <!--text--> */}
              <p className='paragraph'>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
                autem voluptatum sunt ratione, similique dolor.
              </p>
              {/* <!--info--> */}
              <a href='#' className='contact-us-info contact-us-info-link'>
                Diption.group@gmail.com
              </a>
            </div>
          </div>
        </section>
      </main>
      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default ContactUsPage
