import React, { useState, useEffect } from 'react'
import '../styles/Profile.css'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'
import { FaRegHeart, FaHeart, FaSave } from 'react-icons/fa'

import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { MdEditSquare } from 'react-icons/md'

import { IoShareSocialSharp } from 'react-icons/io5'

import { IoMdCloudUpload } from 'react-icons/io'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// image

import avatar from '../assets/images/nft-1.jpg'
import banner from '../assets/images/art-3.jpg'

import avatarDefault from '../assets/images/nft-1.jpg'
import bannerDefault from '../assets/images/art-3.jpg'

import ShopPic24 from '../assets/images/artist-profile-15.jpg'
import ShopPic25 from '../assets/images/artist-profile-16.jpg'
import ShopPic26 from '../assets/images/category-section-img-1.png'
import ShopPic27 from '../assets/images/category-section-img-2.png'
import ShopPic28 from '../assets/images/category-section-img-3.png'
import ShopPic29 from '../assets/images/categ-1.jpg'
import ShopPic30 from '../assets/images/categ-2.jpg'
import ShopPic31 from '../assets/images/categ-3.jpg'
import ShopPic32 from '../assets/images/art-1.jpg'
import ShopPic33 from '../assets/images/art-2.jpg'
import logo from '../assets/images/diption-logo-2.jpg'

// History Cart

function HistoryPage() {
  // navigate
  let navigate = useNavigate()

  const handleProfileIcon = () => {
    navigate('/login')
  }

  // -------------------------- ********Uploader*******------------------------
  const token = sessionStorage.getItem('authToken')
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploadStatus, setUploadStatus] = useState('')
  const [avatarData, setAvatarData] = useState(null)
  const [bannerData, setBannerData] = useState(null)

  const [avatarUrl, setAvatarUrl] = useState(avatarDefault) // URL آواتار
  const [headerUrl, setHeaderUrl] = useState(bannerDefault) // URL بنر

  //
  const handleUpload = async (selectedFile, type) => {
    if (!selectedFile) {
      setUploadStatus('Please select a file first')
      toast.warn('Please select a file first')
      return
    }

    let data = new FormData()
    data.append('file', selectedFile)
    data.append('type', type)

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://yellowjayco.com/api/uploader',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    }

    try {
      const response = await axios.request(config)
      console.log(`Upload response for ${type}:`, JSON.stringify(response.data))
      setUploadStatus('Upload successful!')
      toast.success('Upload successful!')
      const {
        uri,
        extension,
        type: responseType,
        size,
        name,
        alt,
        data: responseData,
      } = response.data.data
      const uploadData = {
        uri,
        extension,
        type: responseType,
        size,
        name,
        alt,
        data: responseData,
      }

      if (type === 'avatar') {
        setAvatar([uploadData]) // به‌روزرسانی مقادیر avatar
      } else if (type === 'banner') {
        setHeader([uploadData]) // به‌روزرسانی مقادیر header
      }
    } catch (error) {
      console.error(error)
      setUploadStatus('Upload failed!')
      toast.error('Upload failed!')
    }
  }

  // profile name

  const [firstName, setFirstName] = useState('Trevor')
  const [lastName, setLastName] = useState('Jones')

  const [avatar, setAvatar] = useState([
    {
      uri: 'https://www.yellowjayco.com//uploads/2e5a797574651e700037a00834fc192cdff92aad.jpg',
      extension: 'jpg',
      type: 'avatar',
      size: 515452,
      name: 'artist-profile-2.jpg',
      alt: 'artist-profile-2.jpg',
      data: null,
    },
  ])

  const [header, setHeader] = useState([
    {
      uri: 'https://www.yellowjayco.com//uploads/b442c44b80917d55cb4fbc18c3549fea88f669d1.jpg',
      extension: 'jpg',
      type: 'avatar',
      size: 127916,
      name: 'art-3.jpg',
      alt: 'art-3.jpg',
      data: null,
    },
  ])

  // update Profile

  const profileUpdate = async () => {
    let data = JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      avatar: avatar,
      header: header,
    })

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: 'https://yellowjayco.com/api/update-profile',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    }

    try {
      const response = await axios.request(config)
      console.log(JSON.stringify(response.data))
      setUploadStatus('Profile update successful!')
      toast.success('Profile update successful!', {
        onClose: () => window.location.reload(), // ریلود صفحه بعد از بسته شدن toast
      })
    } catch (error) {
      console.error(error)
      setUploadStatus('Profile update failed!')
      toast.error('Profile update failed!')
    }
  }

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
  }

  // Like Unlike Handel

  const [liked, setLiked] = useState(false)

  const handleLikeButton = () => {
    setLiked(!liked)
    console.log(liked ? 'unlike' : 'like')
  }

  // Edit Mode

  const [editing, setEditing] = useState(false)

  const handleEditButton = () => {
    setEditing(!editing)
    console.log(editing ? 'save' : 'edit')
  }

  // تابع برای تغییر فایل انتخاب شده و آپلود آن
  const handleFileChange = async (event, type) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    await handleUpload(file, type)
  }

  // Option

  const [activeOption, setActiveOption] = useState('top')

  // DropDown
  // -- Art Styles --
  const [showMenuArt, setShowMenuArt] = useState(true)
  const [checkedStateArt, setCheckedStateArt] = useState({
    modern: false,
    impressionism: false,
    surrealism: false,
    photography: false,
    digitalArt: false,
    calligraphy: false,
  })

  const toggleMenuArt = () => {
    setShowMenuArt(!showMenuArt)
  }

  const handleCheckboxArtChange = (event) => {
    setCheckedStateArt({
      ...checkedStateArt,
      [event.target.name]: event.target.checked,
    })
  }

  // -- Subject --

  const [showDropdownSubject, setShowDropdownSubject] = useState(false)
  const [checkedStatesSubject, setCheckedStatesSubject] = useState({
    facesAndPortraits: false,
    landscapes: false,
    abstract: false,
    animals: false,
    figurative: false,
  })

  const toggleMenuSubject = () => setShowDropdownSubject(!showDropdownSubject)

  const handleCheckboxSubjectChange = (event) => {
    setCheckedStatesSubject({
      ...checkedStatesSubject,
      [event.target.name]: event.target.checked,
    })
  }

  // -- Price--

  const [showDropdownPrice, setShowDropdownPrice] = useState(false)
  const [priceRangeChecks, setPriceRangeChecks] = useState({
    under100: false,
    from100To500: false,
    from500To1000: false,
    above1000: false,
  })

  const toggleMenuPrice = () => setShowDropdownPrice(!showDropdownPrice)

  const handleCheckboxPriceChange = (event) => {
    const { name, checked } = event.target
    setPriceRangeChecks((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  // -- Chains --

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [checkboxes, setCheckboxes] = useState({
    Ethereum: false,
    Polygon: false,
    Bep20: false,
    Other: false,
  })

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }))
  }

  // show Profile user Api

  const [profileData, setProfileData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  //

  // -------------------------- ******** Show Profile *******------------------------

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!token) {
        setError('No auth token found')
        setLoading(false)
        return
      }

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://yellowjayco.com/api/show-profile',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      try {
        const response = await axios.request(config)
        const profile = response.data.data.show_profile
        setProfileData(response.data)
        setFirstName(profile.first_name)
        setLastName(profile.last_name)
        setHeaderUrl(profile.header || bannerDefault) // استفاده از تصویر پیش‌فرض اگر header مقدار نداشته باشد
        setAvatarUrl(profile.avtar || avatarDefault) // استفاده از تصویر پیش‌فرض اگر avatar مقدار نداشته باشد
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    fetchProfileData()
  }, [token])

  // Loading
  if (loading) {
    return (
      <div className='loading-container'>
        <div className='loading-wave'>
          <div className='loading-bar'></div>
          <div className='loading-bar'></div>
          <div className='loading-bar'></div>
          <div className='loading-bar'></div>
        </div>
      </div>
    )
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <ToastContainer />
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile' onClick={handleProfileIcon}>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/* <!--==============profile-header-banner-section=============--> */}
      <section
        className='profile-header-banner-section'
        style={{ backgroundImage: `url(${headerUrl})` }}
      >
        {editing && (
          <>
            <button
              className='profile-banner-edit'
              title='Upload File'
              onClick={() =>
                document.getElementById('file-upload-banner').click()
              }
            >
              <IoMdCloudUpload className='banner-upload-icon' />
            </button>
            <input
              type='file'
              id='file-upload-banner'
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e, 'banner')}
            />
          </>
        )}
      </section>
      {/* <!--==============profile-top-section=============--> */}
      <section className='profile-top-section'>
        <div className='profile-top-container profile-container'>
          <div className='profile-content-wrapper'>
            {/* <!--profile-img-------> */}
            <div className={`profile-img-wrapper ${editing ? 'editing' : ''}`}>
              <img src={avatarUrl} alt='Avatar' />
              {editing && (
                <>
                  <div className='overlay'>
                    <button
                      className='edit-button'
                      title='Upload File'
                      onClick={() =>
                        document.getElementById('file-upload-avatar').click()
                      }
                    >
                      <IoMdCloudUpload className='profile-upload-icon' />
                    </button>
                  </div>
                  <input
                    type='file'
                    id='file-upload-avatar'
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(e, 'avatar')}
                  />
                </>
              )}
            </div>
            <div className='profile-top-background-light background-lights'></div>
            {/* <!--profile-caption--------> */}
            <div className='profile-caption'>
              {/* <!--name-and-joindate-wrapper----> */}
              <div className='profile-name-join-date-wrapper'>
                {/* <!--name----> */}
                {editing ? (
                  <div className='name-input-wrapper'>
                    <input
                      type='text'
                      className='profile-name-input'
                      value={firstName}
                      onChange={handleFirstNameChange}
                      placeholder='First Name'
                    />
                    <input
                      type='text'
                      className='profile-name-input'
                      value={lastName}
                      onChange={handleLastNameChange}
                      placeholder='Last Name'
                    />
                  </div>
                ) : (
                  <h3 className='profile-name'>{`${firstName} ${lastName}`}</h3>
                )}
                {/* <!--join-date----> */}
                <p className='profile-join-date'>
                  <span className='profile-join-date-span'>Joined</span> June
                  2023
                </p>
              </div>
              <div className='profile-like-share-buttons-wrapper'>
                <button
                  className='profile-edit-button'
                  onClick={handleEditButton}
                >
                  {editing ? (
                    <FaSave
                      className='profile-edit-icon profile-button-icon'
                      title='Save'
                      onClick={profileUpdate}
                    />
                  ) : (
                    <MdEditSquare
                      className='profile-edit-icon profile-button-icon'
                      title='Edit'
                    />
                  )}
                </button>
                {/* Like Button */}
                <button
                  className='profile-like-button'
                  title='Like'
                  onClick={handleLikeButton}
                >
                  {liked ? (
                    <FaHeart className='profile-like-icon profile-button-icon' />
                  ) : (
                    <FaRegHeart className='profile-like-icon profile-button-icon' />
                  )}
                </button>
                {/* Share */}
                <button className='profile-share-button' title='Share'>
                  <IoShareSocialSharp className='profile-share-icon profile-button-icon' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* / <!--=============profile-main-section===============--> */}
      <section className='profile-main-section'>
        {/*<!--===========profile-options-section(Top-Popular-Trending)=====--> */}
        <section className='profile-options-and-button-section'>
          <div className='options-and-button-profile-section-container profile-container'>
            {/* <!--profile-option-and-button-wrapper-------> */}
            <div className='profile-option-and-button-wrapper'>
              {/*<!--profile-option-content-wrapper-------------> */}
              <div className='profile-option-content-wrapper'>
                <div className='profile-options-wrapper'>
                  <button
                    className={`profile-option-button ${
                      activeOption === 'top' ? 'active' : ''
                    }`}
                    onClick={() => setActiveOption('top')}
                  >
                    Top Artworks
                  </button>
                  <button
                    className={`profile-option-button ${
                      activeOption === 'popular' ? 'active' : ''
                    }`}
                    onClick={() => setActiveOption('popular')}
                  >
                    Popular
                  </button>
                  <button
                    className={`profile-option-button ${
                      activeOption === 'trending' ? 'active' : ''
                    }`}
                    onClick={() => setActiveOption('trending')}
                  >
                    Trending
                  </button>
                  <div
                    className={`active-box ${
                      activeOption === 'top'
                        ? 'active-box-start-top-section'
                        : activeOption === 'popular'
                        ? 'active-box-start-popular-section'
                        : 'active-box-start-trending-section'
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  <!--==========profile-filter-and-boxes-wrapper================--> */}
        <section className='profile-filters-and-boxes-section'>
          {/* <!--====setting-profile-container=================--> */}
          <div className='profile-filters-and-boxes-container profile-container'>
            <div className='profile-filters-and-boxes-wrapper'>
              <section className='shop-drop-button-section'>
                <div className='profile-drop-button-wrapper'>
                  {/* art Styles */}
                  <div className='dropdown-btn-shop'>
                    <div className='select-shop' onClick={toggleMenuArt}>
                      <span className='selected'>Art Styles</span>
                      <FontAwesomeIcon icon={faChevronDown} className='cart' />
                    </div>
                    {showMenuArt && (
                      <ul className='drop-menu-shop  art-style-drop-menu'>
                        <li>
                          <input
                            type='checkbox'
                            id='modern-check'
                            name='modern'
                            checked={checkedStateArt.modern}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='modern-check'>Modern</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='impressionism-check'
                            name='impressionism'
                            checked={checkedStateArt.impressionism}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='impressionism-check'>
                            Impressionism
                          </label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='surrealism-check'
                            name='surrealism'
                            checked={checkedStateArt.surrealism}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='surrealism-check'>Surrealism</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='photography-check'
                            name='photography'
                            checked={checkedStateArt.photography}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='photography-check'>Photography</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='digital-art-check'
                            name='digitalArt'
                            checked={checkedStateArt.digitalArt}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='digital-art-check'>Digital Art</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='calligraphy-check'
                            name='calligraphy'
                            checked={checkedStateArt.calligraphy}
                            onChange={handleCheckboxArtChange}
                          />
                          <label htmlFor='calligraphy-check'>Calligraphy</label>
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* Subject */}
                  <div className='dropdown-btn-shop'>
                    <div className='select-shop' onClick={toggleMenuSubject}>
                      <span className='selected'>Subject</span>
                      <FontAwesomeIcon icon={faChevronDown} className='cart' />
                    </div>
                    {showDropdownSubject && (
                      <ul className='drop-menu-shop subject-drop-menu'>
                        <li>
                          <input
                            type='checkbox'
                            id='faces-and-portraits-check'
                            name='facesAndPortraits'
                            checked={checkedStateArt.facesAndPortraits}
                            onChange={handleCheckboxSubjectChange}
                          />
                          <label htmlFor='faces-and-portraits-check'>
                            Faces & Portraits
                          </label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='landscapes-check'
                            name='landscapes'
                            checked={checkedStateArt.landscapes}
                            onChange={handleCheckboxSubjectChange}
                          />
                          <label htmlFor='landscapes-check'>Landscapes</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='Abstract-check'
                            name='abstract'
                            checked={checkedStateArt.abstract}
                            onChange={handleCheckboxSubjectChange}
                          />
                          <label htmlFor='Abstract-check'>Abstract</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='Animals-check'
                            name='animals'
                            checked={checkedStateArt.animals}
                            onChange={handleCheckboxSubjectChange}
                          />
                          <label htmlFor='Animals-check'>Animals</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='figurative-check'
                            name='figurative'
                            checked={checkedStateArt.figurative}
                            onChange={handleCheckboxSubjectChange}
                          />
                          <label htmlFor='figurative-check'>Figurative</label>
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* Price */}
                  <div className='dropdown-btn-shop'>
                    <div className='select-shop' onClick={toggleMenuPrice}>
                      <span className='selected'>Price</span>
                      <FontAwesomeIcon icon={faChevronDown} className='cart' />
                    </div>
                    {showDropdownPrice && (
                      <ul className='drop-menu-shop price-drop-menu'>
                        <li>
                          <input
                            type='checkbox'
                            id='under-100-check'
                            name='under100'
                            checked={priceRangeChecks.under100}
                            onChange={handleCheckboxPriceChange}
                          />
                          <label htmlFor='under-100-check'>Under $100</label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='from-100-to-500-check'
                            name='from100To500'
                            checked={priceRangeChecks.from100To500}
                            onChange={handleCheckboxPriceChange}
                          />
                          <label htmlFor='from-100-to-500-check'>
                            $100 to $500
                          </label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='from-500-to-1000-check'
                            name='from500To1000'
                            checked={priceRangeChecks.from500To1000}
                            onChange={handleCheckboxPriceChange}
                          />
                          <label htmlFor='from-500-to-1000-check'>
                            $500 to $1,000
                          </label>
                        </li>
                        <li>
                          <input
                            type='checkbox'
                            id='above-1000-check'
                            name='above1000'
                            checked={priceRangeChecks.above1000}
                            onChange={handleCheckboxPriceChange}
                          />
                          <label htmlFor='above-1000-check'>Above $1,000</label>
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* Chains */}
                  <div className='dropdown-btn-shop'>
                    <div className='select-shop' onClick={toggleDropdown}>
                      <span className='selected'>Chains</span>
                      <FontAwesomeIcon icon={faChevronDown} className='cart' />
                    </div>
                    {isDropdownOpen && (
                      <ul className='drop-menu-shop chains-drop-menu'>
                        {Object.entries(checkboxes).map(([key, value]) => (
                          <li key={key}>
                            <input
                              type='checkbox'
                              id={`${key}-check`}
                              name={key}
                              checked={value}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor={`${key}-check`}>
                              {key.charAt(0).toUpperCase() +
                                key.slice(1).replace('-', ' ')}
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </section>

              {/* <!--=================profile-boxes=========----------> */}

              <section className='profile-boxs-section'>
                <div className='profile-box-wrapper'>
                  <div className='profile-box-section-background-light background-light'></div>
                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={banner} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>

                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={ShopPic33} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>

                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={ShopPic32} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>

                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={ShopPic31} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>

                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={ShopPic30} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>

                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={ShopPic29} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>

                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={ShopPic28} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>

                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={ShopPic27} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>

                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={ShopPic26} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>

                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={ShopPic25} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>

                  {/*<!--item-----------> */}
                  <a href='/' className='profile-product-item-link'>
                    <div className='profile-product-box'>
                      {/* <!--img------> */}
                      <div className='profile-product-box-img-wrapper'>
                        <img src={ShopPic24} alt='img' />
                      </div>
                      {/* <!--caption------> */}
                      <div className='profile-product-box-caption'>
                        {/* <!--caption-deatails-----> */}
                        <div className='caption-deatails'>
                          {/* <!--item-name----------> */}
                          <strong className='profile-product-name'>
                            3D holographic
                          </strong>
                          {/* <!--price-----> */}
                          <p className='profile-product-price'>0.8 ETH</p>
                        </div>
                        {/* <!--creator------> */}
                        <p className='profile-product-creator'>Trevor Jones</p>
                      </div>
                    </div>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </section>
      </section>
      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default HistoryPage
