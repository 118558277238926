import React, { useState, useRef, useEffect } from 'react'
import {
  FaChevronDown,
  FaChevronUp,
  FaArrowRight,
  FaArrowLeft,
} from 'react-icons/fa'
import '../styles/Home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'
import { FaBars } from 'react-icons/fa'

import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { CiLogout } from 'react-icons/ci'

// Wallet

import {
  ThirdwebProvider,
  ConnectWallet,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  localWallet,
  embeddedWallet,
} from '@thirdweb-dev/react'

import { useAddress } from '@thirdweb-dev/react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import headerImgBanner from '../assets/images/header-img-banner.png'
import ShopPic1 from '../assets/images/nft-1.jpg'
import ShopPic2 from '../assets/images/nft-2.jpg'
import ShopPic3 from '../assets/images/nft-3.jpg'
import ShopPic4 from '../assets/images/shop-img.jpg'
import ShopPic5 from '../assets/images/nft-5.jpg'
import ShopPic6 from '../assets/images/nft-6.jpg'
import ShopPic7 from '../assets/images/nft-7.jpg'
import ShopPic8 from '../assets/images/nft-8.jpg'
import ShopPic9 from '../assets/images/nft-9.jpg'
import ShopPic10 from '../assets/images/nft-10.jpg'
import ShopPic11 from '../assets/images/nft-11.jpg'
import ShopPic12 from '../assets/images/nft-12.jpg'
import ShopPic13 from '../assets/images/nft-13.jpg'
import ShopPic14 from '../assets/images/nft-14.jpg'
import ShopPic15 from '../assets/images/artist-profile-1.jpg'
import ShopPic16 from '../assets/images/artist-profile-2.jpg'
import ShopPic17 from '../assets/images/artist-profile-3.jpg'
import ShopPic18 from '../assets/images/artist-profile-4.jpg'
import ShopPic19 from '../assets/images/category-banner-img.jpg'
import ShopPic20 from '../assets/images/artist-profile-11.jpg'
import ShopPic21 from '../assets/images/artist-profile-12.jpg'
import ShopPic22 from '../assets/images/artist-profile-13.jpg'
import ShopPic23 from '../assets/images/artist-profile-14.jpg'
import ShopPic24 from '../assets/images/artist-profile-15.jpg'
import ShopPic25 from '../assets/images/artist-profile-16.jpg'
import ShopPic26 from '../assets/images/category-section-img-1.png'
import ShopPic27 from '../assets/images/category-section-img-2.png'
import ShopPic28 from '../assets/images/category-section-img-3.png'
import ShopPic29 from '../assets/images/categ-1.jpg'
import ShopPic30 from '../assets/images/categ-2.jpg'
import ShopPic31 from '../assets/images/categ-3.jpg'
import ShopPic32 from '../assets/images/art-1.jpg'
import ShopPic33 from '../assets/images/art-2.jpg'
import ShopPic34 from '../assets/images/art-3.jpg'
import logo from '../assets/images/diption-logo-2.jpg'

const products = [
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic1,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic2,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic3,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic4,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic5,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic25,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic23,
  },
]

const PuplarProducts = [
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic6,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic7,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic8,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic9,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic10,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic11,
  },
]

const TrendingProducts = [
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic12,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic13,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic14,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic1,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic2,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic2,
  },
]

const TopProducts = [
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic3,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic4,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic5,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic6,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic7,
  },
]

const TopArtistCart = [
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic15,
  },
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic16,
  },
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic17,
  },
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic18,
  },
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic19,
  },
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic3,
  },
]

const PapularArtistCart = [
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic20,
  },
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic21,
  },
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic23,
  },
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic24,
  },
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic22,
  },
  {
    name: 'Trevor Jones',
    price: '0.8 ETH',
    image: ShopPic25,
  },
]

function HomePage() {
  // navigate
  let navigate = useNavigate()

  const handleProfileIcon = () => {
    const token = sessionStorage.getItem('authToken')
    if (token) {
      navigate('/profile')
    } else {
      navigate('/login')
    }
  }

  // // Check AuthToken
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const token = sessionStorage.getItem('authToken')
    if (token) {
      setIsAuthenticated(true)
    }
  }, [])

  // Logout Handle

    const handleLogout = () => {
      sessionStorage.removeItem('authToken')
      setIsAuthenticated(false)
      toast.success('Logout successful', {
        onClose: () => window.location.reload(),
      })
    }

  // DropDown

  const dropdownRef = useRef(null)
  const subjectRef = useRef(null)
  const figmaRef = useRef(null)
  const colorRef = useRef(null)
  const priceRef = useRef(null)

  // Click in other and close
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenArt(false)
      }
      if (subjectRef.current && !subjectRef.current.contains(event.target)) {
        setIsOpenSubject(false)
      }
      if (figmaRef.current && !figmaRef.current.contains(event.target)) {
        setIsOpenFigma(false)
      }
      if (colorRef.current && !colorRef.current.contains(event.target)) {
        setIsOpenColor(false)
      }
      if (priceRef.current && !priceRef.current.contains(event.target)) {
        setIsOpenPrice(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const [isOpenBurger, setIsOpenBurger] = useState(false)
  const toggleDropdownBurger = () => setIsOpenBurger(!isOpenBurger)

  // -- Art --
  const [isOpenArt, setIsOpenArt] = useState(false)
  const toggleDropdownArt = () => setIsOpenArt(!isOpenArt)

  // -- Subject --
  const [isOpenSubject, setIsOpenSubject] = useState(false)
  const toggleDropdownSubject = () => setIsOpenSubject(!isOpenSubject)

  // -- Figma --
  const [isOpenFigma, setIsOpenFigma] = useState(false)
  const toggleDropdownFigma = () => setIsOpenFigma(!isOpenFigma)

  // -- Color --
  const [isOpenColor, setIsOpenColor] = useState(false)
  const toggleDropdownColor = () => setIsOpenColor(!isOpenColor)

  // -- Price --
  const [isOpenPrice, setIsOpenPrice] = useState(false)
  const toggleDropdownPrice = () => setIsOpenPrice(!isOpenPrice)

  // Product Scroll
  const popularRef = useRef(null)
  const shopRef = useRef(null)

  // recomendetion scroll

  const scrollContainerpopular = (ref, direction) => {
    const scrollAmount = 300 // Adjust this value as needed
    const currentRef = ref.current

    if (direction === 'left') {
      currentRef.scrollLeft -= scrollAmount
    } else {
      currentRef.scrollLeft += scrollAmount
    }
  }

  // shop scroll

  const scrollContainershop = (ref, direction) => {
    const scrollAmount = 100 // Adjust this value as needed
    const currentRef = ref.current

    if (direction === 'left') {
      currentRef.scrollLeft -= scrollAmount
    } else {
      currentRef.scrollLeft += scrollAmount
    }
  }

  // Recomendetions
  const [selectedOption, setSelectedOption] = useState('popular-products')

  const handleChange = (event) => {
    setSelectedOption(event.target.id)
  }

  // artist profile

  const [selectedCategory, setSelectedCategory] = useState('TopSeller')

  const handleClick = (category, event) => {
    event.preventDefault()
    setSelectedCategory(category)
  }

  // Join

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('Form submitted')
  }

  // Cart Changer

  // Wallet

  const address = useAddress()

  const isConnected = !!address

  return (
    <ThirdwebProvider
      activeChain='sepolia'
      clientId='50c64a2d7887dce01a80f25afa3226b5'
      supportedWallets={[
        metamaskWallet(),
        coinbaseWallet({ recommended: true }),
        walletConnect(),
        localWallet(),
        embeddedWallet({
          auth: {
            options: ['email', 'google', 'apple', 'facebook'],
          },
        }),
      ]}
    >
      <div>
        {/* Navbar */}
        <section className='header-top-section'>
          <ToastContainer />
          <div className='container'>
            <nav className='navigation'>
              <div className='nav-left-wrapper'>
                <a href='/' className='logo-link'>
                  <img className='logo-home' src={logo} alt='logo-img' />
                </a>
              </div>
              <div className='nav-right-wrapper'>
                <div className='nav-list-wrapper'>
                  <ul className='menu'>
                    <li>
                      <a href='/create'>Create</a>
                    </li>
                    <li>
                      <a href='/podcast-article'>Product & Articles</a>
                    </li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                  </ul>
                </div>
                <div className='nav-search-and-link-wrapper'>
                  <form action='#' className='search-wrapper'>
                    <button type='submit' className='search-btn'>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                    <input
                      type='search'
                      className='search-input'
                      placeholder='Search'
                    />
                  </form>
                  <Link to='/shop' className='shop-btn nav-link-btns'>
                    <FontAwesomeIcon icon={faBagShopping} />
                  </Link>
                  <div className='home-container-account'>
                    <div className='dropdown-btn-profile'>
                      <div
                        className='select-profile'
                        onClick={handleProfileIcon}
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          className='profile-icon'
                          title='Profile'
                        />
                      </div>
                    </div>
                    {isAuthenticated && (
                      <div className='dropdown-btn-profile'>
                        <div
                          className='select-profile logout-icon-left'
                          onClick={handleLogout}
                        >
                          <FontAwesomeIcon
                            icon={faSignOutAlt}
                            className='profile-icon logout-icon'
                            title='Logout'
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='dropdown-btn-profile'>
                    {/* <!--dorop-botton------> */}
                    <div className='custom-dropdown-berger'>
                      <div className='' onClick={toggleDropdownBurger}>
                        <span className='selected-icon'>
                          <FaBars className='profile-icon-burger' />
                        </span>
                      </div>
                      {isOpenBurger && (
                        <ul className='dropdown-list-burger'>
                          {/* <!--item--> */}
                          <li>
                            <a href='/shop'>Shop</a>
                          </li>
                          {/* <!--item--> */}
                          <li>
                            <a href='/profile'>Profile</a>
                          </li>
                          {/* <!--item--> */}
                          <li>
                            <a href='/recommendation'>Recomendition</a>
                          </li>
                          {/* <!--active-item---> */}
                          <li>
                            <a href='/history'>History</a>
                          </li>
                          {/* <!--item--> */}
                          <li>
                            <a href='/favorite'>Favorite</a>
                          </li>
                          {/* <!--item--> */}
                          <li>
                            <a href='/artist-profile'>Artist Profile</a>
                          </li>
                          {/* <!--item--> */}
                          <li>
                            <a href='/collection'>Collection</a>
                          </li>
                          {/* <!--item--> */}
                          <li>
                            <a href='/nft-details'>Nft details</a>
                          </li>
                          <li>
                            <a href='/plans'>Plans</a>
                          </li>
                          <li>
                            <a href='/contact-us'>Contact Us</a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div
                    className={`wallet-connected ${
                      isConnected ? 'Wallet' : ''
                    }`}
                  >
                    <ConnectWallet
                      className='connect-button'
                      theme={'dark'}
                      showThirdwebBranding={false}
                      btnTitle={
                        isConnected ? ' Wallet' : 'Wallet'
                        // 'Connect Wallet'
                      }
                    />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </section>
        {/* Hero */}
        <section className='header-bottom-section'>
          <div className='container'>
            <div className='content-wrapper'>
              <div className='header-background-light'></div>
              <div className='text-box-container'>
                <div className='text-box'>
                  {/* <!--title----------> */}
                  <h1>Discover , Collect and sell Arts & NFTs</h1>
                  {/* <!--Addetional-deatails-for-title----------> */}
                  <p>
                    The world's Largest marketplace for crypto collection and
                    non-fungibite NFTs
                  </p>
                  {/* <!--links----------> */}
                  <div className='home-link-wrapper'>
                    {/* <!--Export-link--> */}
                    <a href='/' className='export'>
                      Explore
                    </a>
                    {/* <!--create-link--> */}
                    <a href='/create' className='create'>
                      Create
                    </a>
                  </div>
                </div>
              </div>
              <div className='circle-section-container'>
                <div className='circle-section'>
                  <div className='main-circle'>
                    <div className='circle circle-1'>
                      <div className='circle circle-2'>
                        <div className='circle circle-3'>
                          <div className='img-background-light'></div>
                        </div>
                      </div>
                    </div>
                    <img src={headerImgBanner} alt='img'></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ---------- main section start------------ */}
        <main className='main'>
          {/* <!---setting-container--> */}
          <div className='container'>
            {/* <!--=======shop-section================================--> */}
            <section className='shop-section'>
              {/* <!--=======shop-filters==================--> */}
              <div className='shop-filters-wrapper'>
                {/* <!--dorop-botton------> */}
                <div className='custom-dropdown' ref={dropdownRef}>
                  <div className='dropdown-header' onClick={toggleDropdownArt}>
                    <span className='selected'>Art Style</span>
                    {isOpenArt ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                  {isOpenArt && (
                    <ul className='dropdown-list'>
                      {/* <!--item--> */}
                      <li>Modern</li>
                      {/* <!--item--> */}
                      <li>Impressionism</li>
                      {/* <!--item--> */}
                      <li>Surrealism</li>
                      {/* <!--active-item---> */}
                      <li>Photography</li>
                      {/* <!--item--> */}
                      <li>Digital Art</li>
                      {/* <!--item--> */}
                      <li>Calligraphy</li>
                    </ul>
                  )}
                </div>
                {/* <!--dorop-botton------> */}
                <div className='custom-dropdown' ref={subjectRef}>
                  <div
                    className='dropdown-header'
                    onClick={toggleDropdownSubject}
                  >
                    <span className='selected'>Subject</span>
                    {isOpenSubject ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                  {isOpenSubject && (
                    <ul className='dropdown-list'>
                      {/* <!--item--> */}
                      <li>Faces & Portraits</li>
                      {/* <!--item--> */}
                      <li>Landscapes</li>
                      {/* <!--item--> */}
                      <li>Abstract</li>
                      {/* <!--item--> */}
                      <li>Animals</li>
                      {/* <!--item--> */}
                      <li>Figurative</li>
                    </ul>
                  )}
                </div>
                {/* <!--dorop-botton------> */}
                <div className='custom-dropdown' ref={figmaRef}>
                  <div
                    className='dropdown-header'
                    onClick={toggleDropdownFigma}
                  >
                    <span className='selected'>Figma</span>
                    {isOpenFigma ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                  {isOpenFigma && (
                    <ul className='dropdown-list'>
                      {/* <!--item--> */}
                      <li>Framer</li>
                      {/* <!--item--> */}
                      <li>sketch</li>
                      {/* <!--item--> */}
                      <li>Invision studio</li>
                      {/* <!--active-item---> */}
                      <li className='active-item'>figma</li>
                      {/* <!--item--> */}
                      <li>Adobe XD</li>
                    </ul>
                  )}
                </div>
                {/* <!--dorop-botton------> */}
                <div className='custom-dropdown' ref={colorRef}>
                  <div
                    className='dropdown-header'
                    onClick={toggleDropdownColor}
                  >
                    <span className='selected'>Color</span>
                    {isOpenColor ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                  {isOpenColor && (
                    <ul className='dropdown-list'>
                      {/* <!--item--> */}
                      <li>Warm</li>
                      {/* <!--item--> */}
                      <li>Cool</li>
                      {/* <!--item--> */}
                      <li>Black & White</li>
                    </ul>
                  )}
                </div>
                {/* <!--dorop-botton------> */}
                <div className='custom-dropdown' ref={priceRef}>
                  <div
                    className='dropdown-header'
                    onClick={toggleDropdownPrice}
                  >
                    <span className='selected'>Price</span>
                    {isOpenPrice ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                  {isOpenPrice && (
                    <ul className='dropdown-list'>
                      {/* <!--item--> */}
                      <li>Under $100</li>
                      {/* <!--item--> */}
                      <li>$100 to $500</li>
                      {/* <!--item--> */}
                      <li>$500 to $1,000</li>
                      {/* <!--item---> */}
                      <li>Above $1,000</li>
                    </ul>
                  )}
                </div>
              </div>
              {/* <!--=======shop-slider==================--> */}
              <div className='shop-background-light'></div>
              <div className='products-container'>
                <div className='products-container-wrapper'>
                  <div className='products-container'>
                    <div className='shop-product-items-wrapper' ref={shopRef}>
                      {products.map((product, index) => (
                        <a
                          key={index}
                          href='/'
                          className='shop-product-item-link'
                        >
                          <div className='shop-product-box'>
                            <div className='shop-product-box-img-wrapper'>
                              <img src={product.image} alt='img' />
                            </div>
                            <div className='shop-product-box-caption'>
                              <div className='caption-details'>
                                <strong className='shop-product-name'>
                                  {product.name}
                                </strong>
                                <p className='shop-product-price'>
                                  {product.price}
                                </p>
                              </div>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                  <span
                    className='slider-btn perv-btn'
                    onClick={() => scrollContainershop(shopRef, 'left')}
                  >
                    <span className='slider-btn-border perv-btn-border'></span>
                    <FaArrowLeft className='fa-arrow-left slider-btn-icon' />
                  </span>
                  <span
                    className='slider-btn next-btn'
                    onClick={() => scrollContainershop(shopRef, 'right')}
                  >
                    <span className='slider-btn-border next-btn-border'></span>
                    <FaArrowRight className=' slider-btn-icon' />
                  </span>
                </div>
                <div>
                  <div className='view-all-wrapper shop-view-all-wrapper'>
                    <Link to='/shop' className='view-all-link'>
                      View All
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            {/* <!--=======Recommendation-section================================--> */}
            <section className='section'>
              <div className='input-select-section'>
                <div className='recommention-background-light'></div>
                <input
                  type='radio'
                  name='radio'
                  id='popular-products'
                  checked={selectedOption === 'popular-products'}
                  onChange={handleChange}
                  hidden
                />
                <input
                  type='radio'
                  name='radio'
                  id='trending'
                  checked={selectedOption === 'trending'}
                  onChange={handleChange}
                  hidden
                />
                <input
                  type='radio'
                  name='radio'
                  id='top'
                  checked={selectedOption === 'top'}
                  onChange={handleChange}
                  hidden
                />
                <div className='labels-wrapper'>
                  <label
                    htmlFor='popular-products'
                    className='input_label popular-products'
                  >
                    Popular this week
                  </label>
                  <label htmlFor='trending' className='input_label trending'>
                    Trending
                  </label>
                  <label htmlFor='top' className='input_label top'>
                    Top
                  </label>
                </div>
              </div>
              <div className='products-container-wrapper'>
                <div className='products-container'>
                  <div className='shop-product-items-wrapper'>
                    <div
                      className='popular-products-container'
                      ref={popularRef}
                    >
                      {selectedOption === 'popular-products' &&
                        PuplarProducts.map((product, index) => (
                          <div className='product-box box1' key={index}>
                            <div className='img-wrapper'>
                              <img src={product.image} alt={product.name} />
                            </div>
                            <div className='caption'>
                              <div className='caption-title-and-price-wrapper'>
                                <strong>{product.name}</strong>
                                <span>{product.price}</span>
                              </div>
                              <div className='caption-creator-and-view-button-wrapper'>
                                <span>{product.creator}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      {selectedOption === 'trending' &&
                        TrendingProducts.map((product, index) => (
                          <div className='product-box box1' key={index}>
                            <div className='img-wrapper'>
                              <img src={product.image} alt={product.name} />
                            </div>
                            <div className='caption'>
                              <div className='caption-title-and-price-wrapper'>
                                <strong>{product.name}</strong>
                                <span>{product.price}</span>
                              </div>
                              <div className='caption-creator-and-view-button-wrapper'>
                                <span>{product.creator}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      {selectedOption === 'top' &&
                        TopProducts.map((product, index) => (
                          <div className='product-box box1' key={index}>
                            <div className='img-wrapper'>
                              <img src={product.image} alt={product.name} />
                            </div>
                            <div className='caption'>
                              <div className='caption-title-and-price-wrapper'>
                                <strong>{product.name}</strong>
                                <span>{product.price}</span>
                              </div>
                              <div className='caption-creator-and-view-button-wrapper'>
                                <span>{product.creator}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <span
                  className='slider-btn perv-btn recommendation-slider-btn-perv'
                  onClick={() => scrollContainerpopular(popularRef, 'left')}
                >
                  <span className='slider-btn-border perv-btn-border'></span>
                  <FaArrowLeft className='fa-arrow-left slider-btn-icon' />
                </span>
                <span
                  className='slider-btn next-btn recommendation-slider-btn-next'
                  onClick={() => scrollContainerpopular(popularRef, 'right')}
                >
                  <span className='slider-btn-border next-btn-border'></span>
                  <FaArrowRight className=' slider-btn-icon' />
                </span>
              </div>
              <div>
                <div>
                  <div className='view-all-wrapper'>
                    <Link to='/recommendation' className='view-all-link'>
                      View All
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            {/*  <!--=======Artist-profile-section================================--> */}
            <section className='artist-profile-section'>
              <div className='artist-section-title-link-wrapper'>
                {/* <!--title------------------> */}
                <h4>Artist Profiles</h4>
                {/* <!--links-----------------> */}
                <div className='artist-link-wrapper'>
                  <a href='/' onClick={(e) => handleClick('TopSeller', e)}>
                    Top Seller
                  </a>
                  <a href='/' onClick={(e) => handleClick('Popular', e)}>
                    Popular
                  </a>
                </div>
              </div>
              {/*  <!--Profile-Slider-------------------------------------------> */}
              <div className='artist-profile-slider-wrapper'>
                {/* <!--Profile-items-container-------------------------------------------> */}
                <div className='artist-profiles-box-contaienr'>
                  {/* <!--Profile-item-------------------------------------------> */}
                  <div className='artist-profiles-wrapper'>
                    {selectedCategory === 'TopSeller' &&
                      TopArtistCart.map((artist, index) => (
                        <a href='/' className='artist-profile-link' key={index}>
                          <div className='profile-box'>
                            <div className='profile-img-box'>
                              <img
                                src={artist.image} // استفاده از مسیر تصویر داینامیک هر هنرمند
                                alt={artist.name} // استفاده از نام هنرمند به عنوان متن جایگزین
                              />
                            </div>
                            <div className='profile-text-wrapper'>
                              <div className='profile-name-and-price'>
                                <p className='profile-name'>{artist.name}</p>{' '}
                                {/* نمایش نام هنرمند */}
                                <p className='profile-price'>
                                  {artist.price}
                                </p>{' '}
                                {/* نمایش قیمت اثر هنرمند */}
                              </div>
                            </div>
                          </div>
                        </a>
                      ))}
                    {selectedCategory === 'Popular' &&
                      PapularArtistCart.map((artist, index) => (
                        <a href='/' className='artist-profile-link' key={index}>
                          <div className='profile-box'>
                            <div className='profile-img-box'>
                              <img
                                src={artist.image} // استفاده از مسیر تصویر داینامیک هر هنرمند
                                alt={artist.name} // استفاده از نام هنرمند به عنوان متن جایگزین
                              />
                            </div>
                            <div className='profile-text-wrapper'>
                              <div className='profile-name-and-price'>
                                <p className='profile-name'>{artist.name}</p>{' '}
                                {/* نمایش نام هنرمند */}
                                <p className='profile-price'>
                                  {artist.price}
                                </p>{' '}
                                {/* نمایش قیمت اثر هنرمند */}
                              </div>
                            </div>
                          </div>
                        </a>
                      ))}
                  </div>
                </div>
              </div>
              <div>
                <div className='view-all-wrapper'>
                  <a href='/artist-profile' className='view-all-link'>
                    View All
                    <FaArrowRight />
                  </a>
                </div>
              </div>
            </section>
            {/* <!--=======Category-banner-section================================--> */}
            <section className='category-banner-section'>
              {/* <!--background-light--------> */}
              <div className='category-background-light-left category-banner-light'></div>
              <div className='category-background-light-right category-banner-light'></div>
              {/* <!--main-wrapper-for-this-section------------> */}
              <div className='category-banner-section-content-wrapper'>
                <div className='category-banner-cards-wrapper'>
                  {/* <!--card--------> */}
                  <div className='category-banner-card card-1'>
                    {/* <!---card-img---------------> */}
                    <div className='category-banner-img-wrapper'>
                      <img src={ShopPic19} alt='img' />
                    </div>
                    {/* <!---card-text---------------> */}
                    <div className='card-text-wrapper'>
                      {/* <!--title--------> */}
                      <p className='card-title'>Happy Monkey</p>
                      {/* <!--price--------> */}
                      <p className='card-price'>0.8 ETH</p>
                    </div>
                  </div>
                  {/* <!--card--------> */}
                  <div className='category-banner-card card-2'>
                    {/* <!---card-img---------------> */}
                    <div className='category-banner-img-wrapper'>
                      <img src={ShopPic18} alt='img' />
                    </div>
                    {/* <!---card-text---------------> */}
                    <div className='card-text-wrapper'>
                      {/* <!--title--------> */}
                      <p className='card-title'>Happy Monkey</p>
                      {/* <!--price--------> */}
                      <p className='card-price'>0.8 ETH</p>
                    </div>
                  </div>
                </div>
                {/* <!--banner-text----------------------> */}
                <div className='category-banner-text-box-wrapper'>
                  <div className='category-banner-text-box'>
                    {/* <!--main-text----------------> */}
                    <h4 className='category-bannr-main-text'>
                      Explore the categories for better experience
                    </h4>
                    {/* <!--explore-link--------------------> */}
                    <a href='/' className='category-banner-explore-link'>
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </section>
            {/* <!--=======Category-section================================--> */}
            <section className='category-section'>
              {/* <!--category-title------------> */}
              <div className='artist-section-title-link-wrapper'>
                {/* <!--title------------------> */}
                <h4 className='artist-section-h4'>Categories</h4>
              </div>
              {/* <!--category-slider------------> */}
              <div className='category-section-slider-wrapper'>
                {/* <!--background-light------------------> */}
                <div className='category-background-light'></div>
                {/* <!--category-section-container-------> */}
                <div className='category-section-slider-item-wrapper'>
                  {/* Items */}
                  <a href='/'>
                    <div className='category-section-slider-item category-section-item-1'>
                      <div className='category-section-img-boxes'>
                        <div className='top-section'>
                          <img src={ShopPic26} alt='img' />
                        </div>
                        <div className='bottom-section'>
                          <div className='left-img-wrapper'>
                            <img src={ShopPic27} alt='img' />
                          </div>
                          <div className='right-img-wrapper'>
                            <img src={ShopPic28} alt='img' />
                          </div>
                        </div>
                      </div>
                      <div className='category-section-imgs-caption'>
                        <h6 className='category-caption-title'>Abstract</h6>
                        <p className='category-section-pictures-number'>
                          39 Item
                        </p>
                      </div>
                    </div>
                  </a>
                  {/* Items */}
                  <a href='/'>
                    <div className='category-section-slider-item category-section-item-1'>
                      <div className='category-section-img-boxes'>
                        <div className='top-section'>
                          <img src={ShopPic29} alt='img' />
                        </div>
                        <div className='bottom-section'>
                          <div className='left-img-wrapper'>
                            <img src={ShopPic30} alt='img' />
                          </div>
                          <div className='right-img-wrapper'>
                            <img src={ShopPic31} alt='img' />
                          </div>
                        </div>
                      </div>
                      <div className='category-section-imgs-caption'>
                        <h6 className='category-caption-title'>Figurative</h6>
                        <p className='category-section-pictures-number'>
                          25 Item
                        </p>
                      </div>
                    </div>
                  </a>
                  {/* Items */}
                  <a href='/'>
                    <div className='category-section-slider-item category-section-item-1'>
                      <div className='category-section-img-boxes'>
                        <div className='top-section'>
                          <img src={ShopPic32} alt='img' />
                        </div>
                        <div className='bottom-section'>
                          <div className='left-img-wrapper'>
                            <img src={ShopPic33} alt='img' />
                          </div>
                          <div className='right-img-wrapper'>
                            <img src={ShopPic34} alt='img' />
                          </div>
                        </div>
                      </div>
                      <div className='category-section-imgs-caption'>
                        <h6 className='category-caption-title'>Art</h6>
                        <p className='category-section-pictures-number'>
                          15 Item
                        </p>
                      </div>
                    </div>
                  </a>
                  {/* Items */}
                  <a href='/'>
                    <div className='category-section-slider-item category-section-item-1'>
                      <div className='category-section-img-boxes'>
                        <div className='top-section'>
                          <img
                            src='./images/category-section-img-1.png'
                            alt='img'
                          />
                        </div>
                        <div className='bottom-section'>
                          <div className='left-img-wrapper'>
                            <img
                              src='./images/category-section-img-2.png'
                              alt='img'
                            />
                          </div>
                          <div className='right-img-wrapper'>
                            <img
                              src='./images/category-section-img-3.png'
                              alt='img'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='category-section-imgs-caption'>
                        <h6 className='category-caption-title'>Abstract</h6>
                        <p className='category-section-pictures-number'>
                          39 Item
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </section>
            {/* <!--=======join-community-section================================--> */}
            <section className='join-community-section'>
              <div className='join-community-content-wrapper'>
                <div className='join-community-text-input-container'>
                  <div className='join-community-text-input-container'>
                    <strong className='join-text-title'>
                      Are you digital creator?
                    </strong>
                    <p className='text-details'>
                      Learn how marketplace helps you protect and sell your
                      unique digital creations, and join our growing community
                      of thousand digital creators.
                    </p>
                    <form
                      className='join-community-text-input-wrapper'
                      onSubmit={handleSubmit}
                    >
                      <input type='email' placeholder='Enter your email' />
                      <button type='submit'>Get started</button>
                    </form>
                  </div>
                </div>
                <div className='join-community-text'>
                  <p>Join the community</p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        {/* ---------- main section end------------ */}
        <footer className='footer'>
          <div className='footer-wrapper'>
            <div className='container'>
              <div className='container'>
                <div className='footer-content-wrapper'>
                  <div className='footer-left-wrapper'>
                    <strong className='footer-title'> DIPTION </strong>
                    <p className='footer-text-wrapper'>
                      The world's largest digital marketplace for crypto
                      collectibles and non-fungible tokens (NFTs). Buy,sell,and
                      discover exclusive digital items.
                    </p>
                    <div className='social-links-wrapper'>
                      <a href='https://instagram.com'>
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                      <a href='https://twitter.com'>
                        <FontAwesomeIcon icon={faSquareTwitter} />
                      </a>
                      <a href='https://facebook.com'>
                        <FontAwesomeIcon icon={faSquareFacebook} />
                      </a>
                      <a href='https://pinterest.com'>
                        <FontAwesomeIcon icon={faPinterest} />{' '}
                        {/* اضافه شده Pinterest */}
                      </a>
                      <a href='https://telegram.org'>
                        <FontAwesomeIcon icon={faTelegram} />{' '}
                        {/* اضافه شده Telegram */}
                      </a>
                    </div>
                  </div>
                  <div className='footer-right-wrapper'>
                    <ul className='explore-footer-list'>
                      <li className='footer-ul-title'>Resource</li>
                      <li>
                        <a href='#'>Art style</a>
                      </li>
                      <li>
                        <a href='#'>Abstract</a>
                      </li>
                      <li>
                        <a href='#'>Landscape</a>
                      </li>
                      <li>
                        <a href='#'>Animal</a>
                      </li>
                      <li>
                        <a href='#'>Face and portrait</a>
                      </li>
                      <li>
                        <a href='#'>Figurative</a>
                      </li>
                    </ul>

                    <ul className='my-account-footer-list'>
                      <li className='footer-ul-title'>My Account</li>
                      <li>
                        <a href='#'>Diption pto</a>
                      </li>
                      <li>
                        <a href='#'>History</a>
                      </li>
                      <li>
                        <a href='#'>Saved</a>
                      </li>
                      <li>
                        <a href='#'>Wallet</a>
                      </li>
                      <li>
                        <a href='#'>Profile</a>
                      </li>
                    </ul>

                    <ul className='resource-footer-list'>
                      <li className='footer-ul-title'>Resource</li>
                      <li>
                        <a href='#'>Podcasts</a>
                      </li>
                      <li>
                        <a href='#'>Articles</a>
                      </li>
                      <li>
                        <a href='#'>Help center</a>
                      </li>
                      <li>
                        <a href='#'>Learn</a>
                      </li>
                    </ul>

                    <ul className='company-footer-list'>
                      <li className='footer-ul-title'>Company</li>
                      <li>
                        <a href='/about-us'>About us</a>
                      </li>
                      <li>
                        <a href='/contact-us'>Contact us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </ThirdwebProvider>
  )
}

export default HomePage
