import React, { useState, useRef } from 'react'
import '../styles/Shop.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

// image

import ShopPic1 from '../assets/images/nft-1.jpg'
import ShopPic2 from '../assets/images/nft-2.jpg'
import ShopPic3 from '../assets/images/nft-3.jpg'
import ShopPic4 from '../assets/images/shop-img.jpg'
import ShopPic5 from '../assets/images/nft-5.jpg'
import ShopPic6 from '../assets/images/nft-6.jpg'
import ShopPic7 from '../assets/images/nft-7.jpg'
import ShopPic8 from '../assets/images/nft-8.jpg'
import logo from '../assets/images/diption-logo-2.jpg'

function ShopPage() {
  // navigate
  let navigate = useNavigate()

  const handleProfileIcon = () => {
    navigate('/login')
  }

  // DropDown
  // -- Art Styles --
  const [showMenuArt, setShowMenuArt] = useState(true)
  const [checkedStateArt, setCheckedStateArt] = useState({
    modern: false,
    impressionism: false,
    surrealism: false,
    photography: false,
    digitalArt: false,
    calligraphy: false,
  })

  const toggleMenuArt = () => {
    setShowMenuArt(!showMenuArt)
  }

  const handleCheckboxArtChange = (event) => {
    setCheckedStateArt({
      ...checkedStateArt,
      [event.target.name]: event.target.checked,
    })
  }

  // -- Subject --

  const [showDropdownSubject, setShowDropdownSubject] = useState(false)
  const [checkedStatesSubject, setCheckedStatesSubject] = useState({
    facesAndPortraits: false,
    landscapes: false,
    abstract: false,
    animals: false,
    figurative: false,
  })

  const toggleMenuSubject = () => setShowDropdownSubject(!showDropdownSubject)

  const handleCheckboxSubjectChange = (event) => {
    setCheckedStatesSubject({
      ...checkedStatesSubject,
      [event.target.name]: event.target.checked,
    })
  }

  // -- Price--

  const [showDropdownPrice, setShowDropdownPrice] = useState(false)
  const [priceRangeChecks, setPriceRangeChecks] = useState({
    under100: false,
    from100To500: false,
    from500To1000: false,
    above1000: false,
  })

  const toggleMenuPrice = () => setShowDropdownPrice(!showDropdownPrice)

  const handleCheckboxPriceChange = (event) => {
    const { name, checked } = event.target
    setPriceRangeChecks((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  // -- Chains --

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [checkboxes, setCheckboxes] = useState({
    Ethereum: false,
    Polygon: false,
    Bep20: false,
    Other: false,
  })

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }))
  }

  // Products

  const products = [
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic1,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic2,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic3,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic4,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic5,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic6,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic7,
    },
    {
      name: '3D holographic',
      price: '0.8 ETH',
      creator: 'Trevor Jones',
      image: ShopPic8,
    },
  ]

  // Paginations

  const calculatePaginationRange = (
    currentPage,
    totalPages,
    siblingCount = 1
  ) => {
    const totalNumbers = siblingCount + 5 // Start, end, current, two siblings, and ellipses
    const totalBlocks = totalNumbers + 2 // Including ellipses blocks

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - siblingCount)
      const endPage = Math.min(totalPages - 1, currentPage + siblingCount)

      let pages = [1]
      if (startPage > 2) {
        pages.push('...')
      }
      for (let page = startPage; page <= endPage; page++) {
        pages.push(page)
      }
      if (endPage < totalPages - 1) {
        pages.push('...')
      }
      pages.push(totalPages)

      return pages
    }

    return Array.from({ length: totalPages }, (_, i) => i + 1)
  }

  const [totalPages] = useState(64)

  const [currentPage, setCurrentPage] = useState(1)
  const paginationRange = calculatePaginationRange(currentPage, totalPages)

  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile' onClick={handleProfileIcon}>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/*     <!--===============shop-filter-and-boxes-wrapper================--> */}
      <section className='shop-filters-and-boxes-section'>
        {/* <!--====setting-container=================--> */}
        <div className='container-shop'>
          <div className='shop-filters-and-boxes-wrapper'>
            {/* <!--=================shop-drop-btn-section==================------------------> */}
            <section className='shop-drop-button-section'>
              <div className='shop-drop-button-wrapper'>
                {/* art Styles */}
                <div className='dropdown-btn-shop'>
                  <div className='select-shop' onClick={toggleMenuArt}>
                    <span className='selected'>Art Styles</span>
                    <FontAwesomeIcon icon={faChevronDown} className='cart' />
                  </div>
                  {showMenuArt && (
                    <ul className='drop-menu-shop  art-style-drop-menu'>
                      <li>
                        <input
                          type='checkbox'
                          id='modern-check'
                          name='modern'
                          checked={checkedStateArt.modern}
                          onChange={handleCheckboxArtChange}
                        />
                        <label htmlFor='modern-check'>Modern</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='impressionism-check'
                          name='impressionism'
                          checked={checkedStateArt.impressionism}
                          onChange={handleCheckboxArtChange}
                        />
                        <label htmlFor='impressionism-check'>
                          Impressionism
                        </label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='surrealism-check'
                          name='surrealism'
                          checked={checkedStateArt.surrealism}
                          onChange={handleCheckboxArtChange}
                        />
                        <label htmlFor='surrealism-check'>Surrealism</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='photography-check'
                          name='photography'
                          checked={checkedStateArt.photography}
                          onChange={handleCheckboxArtChange}
                        />
                        <label htmlFor='photography-check'>Photography</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='digital-art-check'
                          name='digitalArt'
                          checked={checkedStateArt.digitalArt}
                          onChange={handleCheckboxArtChange}
                        />
                        <label htmlFor='digital-art-check'>Digital Art</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='calligraphy-check'
                          name='calligraphy'
                          checked={checkedStateArt.calligraphy}
                          onChange={handleCheckboxArtChange}
                        />
                        <label htmlFor='calligraphy-check'>Calligraphy</label>
                      </li>
                    </ul>
                  )}
                </div>
                {/* Subject */}
                <div className='dropdown-btn-shop'>
                  <div className='select-shop' onClick={toggleMenuSubject}>
                    <span className='selected'>Subject</span>
                    <FontAwesomeIcon icon={faChevronDown} className='cart' />
                  </div>
                  {showDropdownSubject && (
                    <ul className='drop-menu-shop subject-drop-menu'>
                      <li>
                        <input
                          type='checkbox'
                          id='faces-and-portraits-check'
                          name='facesAndPortraits'
                          checked={checkedStateArt.facesAndPortraits}
                          onChange={handleCheckboxSubjectChange}
                        />
                        <label htmlFor='faces-and-portraits-check'>
                          Faces & Portraits
                        </label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='landscapes-check'
                          name='landscapes'
                          checked={checkedStateArt.landscapes}
                          onChange={handleCheckboxSubjectChange}
                        />
                        <label htmlFor='landscapes-check'>Landscapes</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='Abstract-check'
                          name='abstract'
                          checked={checkedStateArt.abstract}
                          onChange={handleCheckboxSubjectChange}
                        />
                        <label htmlFor='Abstract-check'>Abstract</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='Animals-check'
                          name='animals'
                          checked={checkedStateArt.animals}
                          onChange={handleCheckboxSubjectChange}
                        />
                        <label htmlFor='Animals-check'>Animals</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='figurative-check'
                          name='figurative'
                          checked={checkedStateArt.figurative}
                          onChange={handleCheckboxSubjectChange}
                        />
                        <label htmlFor='figurative-check'>Figurative</label>
                      </li>
                    </ul>
                  )}
                </div>
                {/* Price */}
                <div className='dropdown-btn-shop'>
                  <div className='select-shop' onClick={toggleMenuPrice}>
                    <span className='selected'>Price</span>
                    <FontAwesomeIcon icon={faChevronDown} className='cart' />
                  </div>
                  {showDropdownPrice && (
                    <ul className='drop-menu-shop price-drop-menu'>
                      <li>
                        <input
                          type='checkbox'
                          id='under-100-check'
                          name='under100'
                          checked={priceRangeChecks.under100}
                          onChange={handleCheckboxPriceChange}
                        />
                        <label htmlFor='under-100-check'>Under $100</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='from-100-to-500-check'
                          name='from100To500'
                          checked={priceRangeChecks.from100To500}
                          onChange={handleCheckboxPriceChange}
                        />
                        <label htmlFor='from-100-to-500-check'>
                          $100 to $500
                        </label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='from-500-to-1000-check'
                          name='from500To1000'
                          checked={priceRangeChecks.from500To1000}
                          onChange={handleCheckboxPriceChange}
                        />
                        <label htmlFor='from-500-to-1000-check'>
                          $500 to $1,000
                        </label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='above-1000-check'
                          name='above1000'
                          checked={priceRangeChecks.above1000}
                          onChange={handleCheckboxPriceChange}
                        />
                        <label htmlFor='above-1000-check'>Above $1,000</label>
                      </li>
                    </ul>
                  )}
                </div>
                {/* Chains */}
                <div className='dropdown-btn-shop'>
                  <div className='select-shop' onClick={toggleDropdown}>
                    <span className='selected'>Chains</span>
                    <FontAwesomeIcon icon={faChevronDown} className='cart' />
                  </div>
                  {isDropdownOpen && (
                    <ul className='drop-menu-shop chains-drop-menu'>
                      {Object.entries(checkboxes).map(([key, value]) => (
                        <li key={key}>
                          <input
                            type='checkbox'
                            id={`${key}-check`}
                            name={key}
                            checked={value}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor={`${key}-check`}>
                            {key.charAt(0).toUpperCase() +
                              key.slice(1).replace('-', ' ')}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </section>
            {/* <!--=================shop-boxes=========----------> */}
            <section className='shop-boxs-section-shop'>
              <div className='shop-box-wrapper-container'>
                {/* <!---background-light------------------> */}
                <div className='shop-box-section-background-light background-light'></div>
                {/*<!--item-----------> */}
                {products.map((product, index) => (
                  <a key={index} href='/' className='shop-product-item-link'>
                    <div className='shop-product-box-container'>
                      <div className='shop-product-box-img-wrapper-shop'>
                        <img src={product.image} alt={product.name + ' art'} />
                      </div>
                      <div className='shop-product-box-caption-shop'>
                        <div className='caption-details'>
                          <strong className='shop-product-name'>
                            {product.name}
                          </strong>
                          <p className='shop-product-price'>{product.price}</p>
                        </div>
                        <p className='shop-product-creator'>
                          {product.creator}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </section>
          </div>
        </div>
      </section>
      {/* <!--=================pagination-section=========----------> */}
      <section className='pagination-section'>
        {/* <!--setting-container------> */}
        <div className='shop-pagination-container shop-container'>
          <div className='pagination-wrapper'>
            {/* <!--pagination-btn---------> */}
            <button className='pagination-btn pagination-btn-perv'>
              <i className='fa-solid fa-arrow-left'></i>
            </button>

            {/* <!--pagination-page-numbers---------------------> */}
            <ul>
              <li className='link' value='1' id='active'>
                1
              </li>
              <li className='link' value='2'>
                2
              </li>
              <li className='link' value='3'>
                3
              </li>
              <li className='link' value='4'>
                4
              </li>
              <li className='pagination-text'>...</li>
              <li className='link' value='5'>
                68
              </li>
            </ul>

            {/* <!--pagination-btn-----------> */}
            <button class='pagination-btn pagination-btn-next'>
              <i class='fa-solid fa-arrow-right'></i>
            </button>
          </div>
        </div>
      </section>

      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default ShopPage
