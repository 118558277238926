import React, { useState, useRef } from 'react'
import '../styles/ArtistProfile.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'


// image

import ShopPic1 from '../assets/images/nft-1.jpg'
import ShopPic2 from '../assets/images/nft-2.jpg'


import ShopPic7 from '../assets/images/nft-7.jpg'

import ShopPic20 from '../assets/images/artist-profile-11.jpg'

import ShopPic22 from '../assets/images/artist-profile-13.jpg'
import ShopPic23 from '../assets/images/artist-profile-14.jpg'
import ShopPic24 from '../assets/images/artist-profile-15.jpg'
import ShopPic25 from '../assets/images/artist-profile-16.jpg'

import ShopPic27 from '../assets/images/category-section-img-2.png'
import ShopPic28 from '../assets/images/category-section-img-3.png'
import ShopPic29 from '../assets/images/categ-1.jpg'
import ShopPic30 from '../assets/images/categ-2.jpg'
import ShopPic31 from '../assets/images/categ-3.jpg'
import ShopPic32 from '../assets/images/art-1.jpg'
import ShopPic33 from '../assets/images/art-2.jpg'
import ShopPic34 from '../assets/images/art-3.jpg'

import logo from '../assets/images/diption-logo-2.jpg'



// History Cart


function ArtistProfilePage() {

  // navigate
  let navigate = useNavigate()

  const handleProfileIcon = () => {
    navigate('/login')
  }



  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>

              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile' onClick={handleProfileIcon}>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>

      {/* <!--=======artist-profile-title-and-buttons-section=====--> */}

      <section className='artist-profile-title-buttons-section'>
        {/* <!--setting-container----------> */}
        <div className='artist-profile-title-buttons-container artist-profile-container'>
          {/* <!--setting-wrapper-for-content------------> */}
          <div className='artist-profile-title-buttons-content-wrapper'>
            {/* <!--title----------> */}
            <h2 className='artist-profile-title'>Artist Profile</h2>
            {/* <!--buttons---------> */}
            <div className='artist-profile-buttons-wrapper'>
              {/* <!--button----------> */}
              <button className='artist-profile-button top-seller-button'>
                Top Seller
              </button>
              {/* <!--button----------> */}
              <button className='artist-profile-button popular-button'>
                Popular
              </button>
            </div>
          </div>
          <span className='artist-profile-title-buttons-underline'></span>
        </div>
      </section>

      {/* <!--====artist-profile-boxes-section============--> */}

      <section className='artist-profile-boxes-section'>
        <div className='artist-profile-boxes-container artist-profile-container'>
          {/* <!--background-light-------> */}
          <div className='artist-profile-boxes-background-light background-lights'></div>
        </div>
        {/* <!--boxes-wrapper---------------> */}
        <div className='artist-profile-boxes-wrapper'>
          <div className='artist-profile-box'>
            {/* <!--img-wrapper-------------> */}
            <div className='artist-profile-img-wrapper'>
              {/* <!--img--> */}
              <img src={ShopPic22} alt='img' />
            </div>
            {/*  <!--caption------------> */}
            <div class='artist-profile-caption-wrapper'>
              {/* <!--profile-name-and-seemore-button----------> */}
              <div class='artist-profile-name-see-button-wrapper'>
                {/* <!--name------> */}
                <h5 class='artist-profile-name'>Trevor Jones</h5>

                {/* <!--see-button----> */}
                <a href='#' class='artist-profile-see-more-button'>
                  See more
                </a>
              </div>
              {/* <!--owner-and-volume---------> */}
              <div class='artist-profile-owner-volume-wrapper'>
                {/* <!--owner-wrapper---------> */}
                <div class='artist-profile-owner-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-owner-title'>Owner</p>
                  {/* <!--owner-collections-box--------> */}
                  <div class='artist-profile-owner-collection-number-box'>
                    <p class='artist-profile-owner-collection-number'>
                      39 Collections
                    </p>
                  </div>
                </div>

                {/* <!--volume-wrapper-----------> */}
                <div class='artist-profile-volume-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-volume-title'>Volume</p>
                  {/* <!--volume-price-box--------> */}
                  <div class='artist-profile-Volume-price-box'>
                    <p class='aritst-profile-volume-price'>775.6 ETH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--box----------> */}
          <div class='artist-profile-box'>
            {/* <!--img-wrapper-------------> */}
            <div class='artist-profile-img-wrapper'>
              {/* <!--img--> */}
              <img src={ShopPic25} alt='img' />
            </div>
            {/* <!--caption------------> */}
            <div class='artist-profile-caption-wrapper'>
              {/* <!--profile-name-and-seemore-button----------> */}
              <div class='artist-profile-name-see-button-wrapper'>
                {/* <!--name------> */}
                <h5 class='artist-profile-name'>Trevor Jones</h5>

                {/* <!--see-button----> */}
                <a href='#' class='artist-profile-see-more-button'>
                  See more
                </a>
              </div>
              {/* <!--owner-and-volume---------> */}
              <div class='artist-profile-owner-volume-wrapper'>
                {/* <!--owner-wrapper---------> */}
                <div class='artist-profile-owner-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-owner-title'>Owner</p>
                  {/* <!--owner-collections-box--------> */}
                  <div class='artist-profile-owner-collection-number-box'>
                    <p class='artist-profile-owner-collection-number'>
                      39 Collections
                    </p>
                  </div>
                </div>

                {/* <!--volume-wrapper-----------> */}
                <div class='artist-profile-volume-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-volume-title'>Volume</p>
                  {/* <!--volume-price-box--------> */}
                  <div class='artist-profile-Volume-price-box'>
                    <p class='aritst-profile-volume-price'>775.6 ETH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--box----------> */}
          <div class='artist-profile-box'>
            {/* <!--img-wrapper-------------> */}
            <div class='artist-profile-img-wrapper'>
              {/* <!--img--> */}
              <img src={ShopPic24} alt='img' />
            </div>

            {/* <!--caption------------> */}

            <div class='artist-profile-caption-wrapper'>
              {/* <!--profile-name-and-seemore-button----------> */}
              <div class='artist-profile-name-see-button-wrapper'>
                {/* <!--name------> */}
                <h5 class='artist-profile-name'>Trevor Jones</h5>

                {/* <!--see-button----> */}
                <a href='#' class='artist-profile-see-more-button'>
                  See more
                </a>
              </div>

              {/* <!--owner-and-volume---------> */}
              <div class='artist-profile-owner-volume-wrapper'>
                {/* <!--owner-wrapper---------> */}
                <div class='artist-profile-owner-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-owner-title'>Owner</p>
                  {/* <!--owner-collections-box--------> */}
                  <div class='artist-profile-owner-collection-number-box'>
                    <p class='artist-profile-owner-collection-number'>
                      39 Collections
                    </p>
                  </div>
                </div>

                {/* <!--volume-wrapper-----------> */}
                <div class='artist-profile-volume-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-volume-title'>Volume</p>
                  {/* <!--volume-price-box--------> */}
                  <div class='artist-profile-Volume-price-box'>
                    <p class='aritst-profile-volume-price'>775.6 ETH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--box----------> */}
          <div class='artist-profile-box'>
            {/* <!--img-wrapper-------------> */}
            <div class='artist-profile-img-wrapper'>
              {/* <!--img--> */}
              <img src={ShopPic23} alt='img' />
            </div>

            {/* <!--caption------------> */}

            <div class='artist-profile-caption-wrapper'>
              {/* <!--profile-name-and-seemore-button----------> */}
              <div class='artist-profile-name-see-button-wrapper'>
                {/* <!--name------> */}
                <h5 class='artist-profile-name'>Trevor Jones</h5>

                {/* <!--see-button----> */}
                <a href='#' class='artist-profile-see-more-button'>
                  See more
                </a>
              </div>

              {/* <!--owner-and-volume---------> */}
              <div class='artist-profile-owner-volume-wrapper'>
                {/* <!--owner-wrapper---------> */}
                <div class='artist-profile-owner-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-owner-title'>Owner</p>
                  {/* <!--owner-collections-box--------> */}
                  <div class='artist-profile-owner-collection-number-box'>
                    <p class='artist-profile-owner-collection-number'>
                      39 Collections
                    </p>
                  </div>
                </div>

                {/* <!--volume-wrapper-----------> */}
                <div class='artist-profile-volume-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-volume-title'>Volume</p>
                  {/* <!--volume-price-box--------> */}
                  <div class='artist-profile-Volume-price-box'>
                    <p class='aritst-profile-volume-price'>775.6 ETH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--box----------> */}
          <div class='artist-profile-box'>
            {/* <!--img-wrapper-------------> */}
            <div class='artist-profile-img-wrapper'>
              {/* <!--img--> */}
              <img src={ShopPic20} alt='img' />
            </div>

            {/* <!--caption------------> */}

            <div class='artist-profile-caption-wrapper'>
              {/* <!--profile-name-and-seemore-button----------> */}
              <div class='artist-profile-name-see-button-wrapper'>
                {/* <!--name------> */}
                <h5 class='artist-profile-name'>Trevor Jones</h5>

                {/* <!--see-button----> */}
                <a href='#' class='artist-profile-see-more-button'>
                  See more
                </a>
              </div>

              {/* <!--owner-and-volume---------> */}
              <div class='artist-profile-owner-volume-wrapper'>
                {/* <!--owner-wrapper---------> */}
                <div class='artist-profile-owner-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-owner-title'>Owner</p>
                  {/* <!--owner-collections-box--------> */}
                  <div class='artist-profile-owner-collection-number-box'>
                    <p class='artist-profile-owner-collection-number'>
                      39 Collections
                    </p>
                  </div>
                </div>

                {/* <!--volume-wrapper-----------> */}
                <div class='artist-profile-volume-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-volume-title'>Volume</p>
                  {/* <!--volume-price-box--------> */}
                  <div class='artist-profile-Volume-price-box'>
                    <p class='aritst-profile-volume-price'>775.6 ETH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--box----------> */}
          <div class='artist-profile-box'>
            {/* <!--img-wrapper-------------> */}
            <div class='artist-profile-img-wrapper'>
              {/* <!--img--> */}
              <img src={ShopPic1} alt='img' />
            </div>
            {/* <!--caption------------> */}
            <div class='artist-profile-caption-wrapper'>
              {/* <!--profile-name-and-seemore-button----------> */}
              <div class='artist-profile-name-see-button-wrapper'>
                {/* <!--name------> */}
                <h5 class='artist-profile-name'>Trevor Jones</h5>

                {/* <!--see-button----> */}
                <a href='#' class='artist-profile-see-more-button'>
                  See more
                </a>
              </div>

              {/* <!--owner-and-volume---------> */}
              <div class='artist-profile-owner-volume-wrapper'>
                {/* <!--owner-wrapper---------> */}
                <div class='artist-profile-owner-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-owner-title'>Owner</p>
                  {/* <!--owner-collections-box--------> */}
                  <div class='artist-profile-owner-collection-number-box'>
                    <p class='artist-profile-owner-collection-number'>
                      39 Collections
                    </p>
                  </div>
                </div>

                {/* <!--volume-wrapper-----------> */}
                <div class='artist-profile-volume-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-volume-title'>Volume</p>
                  {/* <!--volume-price-box--------> */}
                  <div class='artist-profile-Volume-price-box'>
                    <p class='aritst-profile-volume-price'>775.6 ETH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--box----------> */}
          <div class='artist-profile-box'>
            {/* <!--img-wrapper-------------> */}
            <div class='artist-profile-img-wrapper'>
              {/* <!--img--> */}
              <img src={ShopPic2} alt='img' />
            </div>
            {/* <!--caption------------> */}
            <div class='artist-profile-caption-wrapper'>
              {/* <!--profile-name-and-seemore-button----------> */}
              <div class='artist-profile-name-see-button-wrapper'>
                {/* <!--name------> */}
                <h5 class='artist-profile-name'>Trevor Jones</h5>

                {/* <!--see-button----> */}
                <a href='#' class='artist-profile-see-more-button'>
                  See more
                </a>
              </div>

              {/* <!--owner-and-volume---------> */}
              <div class='artist-profile-owner-volume-wrapper'>
                {/* <!--owner-wrapper---------> */}
                <div class='artist-profile-owner-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-owner-title'>Owner</p>
                  {/* <!--owner-collections-box--------> */}
                  <div class='artist-profile-owner-collection-number-box'>
                    <p class='artist-profile-owner-collection-number'>
                      39 Collections
                    </p>
                  </div>
                </div>

                {/* <!--volume-wrapper-----------> */}
                <div class='artist-profile-volume-wrapper'>
                  {/* <!--title---------------> */}
                  <p class='artist-profile-volume-title'>Volume</p>
                  {/* <!--volume-price-box--------> */}
                  <div class='artist-profile-Volume-price-box'>
                    <p class='aritst-profile-volume-price'>775.6 ETH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--boxes-wrapper---------------> */}
      </section>
      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default ArtistProfilePage
