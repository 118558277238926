import React, { useState, useRef } from 'react'
import '../styles/LoginRegister.css'
import LoginVideo from '../assets/video/login-01.mp4'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGoogle,
  faLinkedin,
  faApple,
} from '@fortawesome/free-brands-svg-icons'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function LoginPage() {
  // Login
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const navigate = useNavigate()

const handleSubmit = async (e) => {
  e.preventDefault()

  let data = JSON.stringify({
    email: username,
    password: password,
    status: 'true',
  })

  let config = {
    method: 'post',
    url: 'https://yellowjayco.com/api/login-user',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: data,
  }

  try {
    const response = await axios.request(config)
    console.log('Response:', response) // اضافه کردن لاگ برای مشاهده پاسخ کامل

    if (response.status === 200) {
      const token = response.data.data.access_token
      console.log('Access Token:', token) // اضافه کردن لاگ برای مشاهده توکن

      if (token) {
        sessionStorage.setItem('authToken', JSON.stringify(token))
        toast.success('Login successful!', {
          onClose: () => navigate('/'),
        })
      } else {
        toast.error('Token not found in response.')
      }
    } else {
      throw new Error('Unexpected response status: ' + response.status)
    }
  } catch (error) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.message)
    } else {
      toast.error('An error occurred during login.')
    }
  }
}


  return (
    <div className='container-login'>
      <div className='login-register-wrapper'>
        <div className='video-wrapper'>
          <video src={LoginVideo} autoPlay loop muted></video>
        </div>
        {/* <!--forms-----------------> */}
        {/* <!--login-form------------> */}
        <div className='form-wrapper login-form-wrapper'>
          <form className='form login-form' onSubmit={handleSubmit}>
            <h1 className='title login-title'>Login</h1>
            <div className='input_wrapper'>
              <input
                type='text'
                name='name'
                id='username'
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label htmlFor='username' className='input__text'>
                Email
              </label>
            </div>
            <div className='input_wrapper password-input'>
              <input
                type='password'
                name='password'
                id='password'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor='password' className='input__text'>
                Password
              </label>
              <span className='show-button'>show</span>
            </div>
            <div className='remember-forgot-wrapper'>
              <div className='remember-wrapper'>
                <input
                  type='checkbox'
                  name='checkbox'
                  id='remember-check-box'
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label htmlFor='remember-check-box' className='remember-text'>
                  Remember me
                </label>
              </div>
              <a href='#' className='forgot-link'>
                Forgot Password
              </a>
            </div>
            <div className='social-section-container'>
              <div className='text-wrapper'>
                <span className='line-left'></span>
                <p className='text'>Login with</p>
                <span className='line-left'></span>
              </div>
              <div className='social-icons-wrapper'>
                <a href='#'>
                  <FontAwesomeIcon className='google-icon' icon={faGoogle} />
                </a>
                <a href='#'>
                  <FontAwesomeIcon className='google-icon' icon={faLinkedin} />
                </a>
                <a href='#'>
                  <FontAwesomeIcon className='google-icon' icon={faApple} />
                </a>
              </div>
            </div>
            <button type='submit' className='login-form-btn'>
              Login
            </button>
            <div className='login-section'>
              <p className='link-text-p'>Don't have an account ?</p>
              <a className='login-link' href='/register'>
                Register
              </a>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default LoginPage
