import React, { useState, useRef } from 'react'
import '../styles/LoginRegister.css'
import LoginVideo from '../assets/video/login-01.mp4'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGoogle,
  faLinkedin,
  faApple,
} from '@fortawesome/free-brands-svg-icons'
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function RegisterPage() {
  // Register
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [licenseAgreed, setLicenseAgreed] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  // Register Api
  const handleSubmit = (e) => {
    e.preventDefault()

    if (!licenseAgreed) {
      toast.error('You must accept the privacy and policy.')
      return
    }

    const data = JSON.stringify({
      email: email,
      password: password,
      type: 'email',
    })

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://yellowjayco.com/api/register',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data))
        toast.success(response.data.message, {
          onClose: () => {
            window.location.reload() // این خط باعث رفرش کامل صفحه می‌شود
          },
        })
      })
      .catch((error) => {
        console.log(error)
        toast.error('An error occurred. Please try again later.')
        // پردازش خطاها و نمایش پیام خطا به کاربر
      })
  }

  return (
    <div className='container-login'>
      <div className='login-register-wrapper'>
        <div className='video-wrapper'>
          <video src={LoginVideo} autoPlay loop muted></video>
        </div>
        {/* <!--forms-----------------> */}
        {/* <!--login-form------------> */}
        <div className='form-wrapper register-form-wrapper'>
          <form className='form register-form' onSubmit={handleSubmit}>
            <h1 className='title login-title'>Register</h1>
            <div className='input_wrapper email-wrapper'>
              <input
                type='email'
                name='email'
                id='email'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor='email' className='input__text'>
                Email
              </label>
            </div>
            <div className='input_wrapper password-wrapper'>
              <input
                type={showPassword ? 'text' : 'password'}
                name='password'
                id='password'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor='password' className='input__text'>
                Password
              </label>
              {/* Implement show/hide functionality as needed */}
              <span
                className='show-button'
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'hide' : 'show'}
              </span>
            </div>
            <div className='input_wrapper confirm-password-wrapper'>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                name='confirm-password'
                id='confirm-password'
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <label htmlFor='confirm-password' className='input__text'>
                Confirm Password
              </label>
              {/* Implement show/hide functionality as needed */}
              <span
                className='show-button'
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? 'hide' : 'show'}
              </span>
            </div>
            <div className='privacy-policy-section'>
              <input
                className='check-tik'
                type='checkbox'
                name='checkbox'
                id='license'
                checked={licenseAgreed}
                onChange={(e) => setLicenseAgreed(e.target.checked)}
              />
              <label className='privacy-policy' htmlFor='license'>
                I Agree with{' '}
                <a className='privacy' href='#'>
                  privacy
                </a>{' '}
                and{' '}
                <a className='privacy' href='#'>
                  policy
                </a>
              </label>
            </div>
            <div className='social-section-container'>
              <div className='text-wrapper'>
                <span className='line-left'></span>
                <p className='text'>Register with</p>
                <span className='line-left'></span>
              </div>
              <div className='social-icons-wrapper'>
                <a href='#'>
                  <FontAwesomeIcon className='google-icon' icon={faGoogle} />
                </a>
                <a href='#'>
                  <FontAwesomeIcon className='google-icon' icon={faLinkedin} />
                </a>
                <a href='#'>
                  <FontAwesomeIcon className='google-icon' icon={faApple} />
                </a>
              </div>
            </div>
            <button type='submit' className='login-form-btn'>
              Register
            </button>
            <div className='login-section'>
              <p className='link-text-p'>Already have and account ?</p>
              <a className='login-link' href='/login'>
                Login
              </a>
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
    </div>
  )
}

export default RegisterPage
