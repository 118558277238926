import { React, useRef } from 'react'
import '../styles/PodcastArticle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import {
  FaArrowRight,
  FaArrowLeft,
  FaPlay,
  FaPause,
  FaEye,
} from 'react-icons/fa'

import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'

// image
import logo from '../assets/images/diption-logo-2.jpg'
import img1 from '../assets/images/podcast-box-img-1.jpg'
import img2 from '../assets/images/podcast-box-img-2.jpg'
import img3 from '../assets/images/podcast-box-img-3.jpg'
import img4 from '../assets/images/podcast-box-img-4.jpg'
import img5 from '../assets/images/podcast-photo-banner-1.jpg'
import img6 from '../assets/images/article-photo-1.jpg'

import img7 from '../assets/images/about-us-img-1.jpg'
import img8 from '../assets/images/about-us-img-2.jpg'
import img9 from '../assets/images/about-us-img-3.jpg'
import img10 from '../assets/images/about-us-img-4.jpg'
import img11 from '../assets/images/nft-12.jpg'
import img12 from '../assets/images/nft-13.jpg'
import img13 from '../assets/images/nft-14.jpg'

// History Cart

function PodcastArticlePage() {
  // shop scroll

  const shopRef = useRef(null)
  const articleRef = useRef(null)

  const scrollContainershop = (ref, direction) => {
    const scrollAmount = 400 // Adjust this value as needed
    const currentRef = ref.current

    if (direction === 'left') {
      currentRef.scrollLeft -= scrollAmount
    } else {
      currentRef.scrollLeft += scrollAmount
    }
  }

    const scrollContainerarticle = (ref, direction) => {
      const scrollAmount = 400 // Adjust this value as needed
      const currentRef = ref.current

      if (direction === 'left') {
        currentRef.scrollLeft -= scrollAmount
      } else {
        currentRef.scrollLeft += scrollAmount
      }
    }


  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile'>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/*<!--=============header-banner-section================-->*/}

      <header className='podcast-header'>
        {/* <!--setting-container--> */}
        <div className='podcast-container'>
          {/* <!--content-wrapper--> */}
          <div className='podcast-header-content-wrapper'>
            {/*<!--text-box--> */}
            <div className='podcast-header-text-box'>
              {/* <!--main-title--> */}
              <h1 className='heading-text'>
                <span className='heading-text-main'>The Power of Podcasts</span>
                <span className='heading-text-sub'>
                  Explore a world of engaging stories, insightful interviews,
                  and expert discussions with our curated podcasts. Designed to
                  inspire, educate, and entertain, our selection offers fresh
                  perspectives on diverse topics.
                </span>
                <span className='heading-caption'>
                  Start your immersive audio journey with us today and see how
                  podcasts can enrich your daily life.
                </span>
              </h1>
              {/* <!--btn--> */}
              <a href='#' className='header-btn'>
                See All Podcasts <i className='fa-solid fa-arrow-right'></i>
              </a>
            </div>
            {/*<!--hero-wrapper--> */}
            <div className='podcast-hero-wrapper'>
              <img src={img5} alt='img' class='hero-img' />
            </div>
          </div>
        </div>
      </header>

      {/* <!--=============main-section================--> */}

      <main className='podcast-main'>
        {/* <!--==========Latest-Episodes-section==============--> */}
        <section className='lastest-episodes-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/*<!--latest-episodes-main-wrapper-->*/}
            <div className='latest-episodes-content-wrapper'>
              {/* <!--title-and-view-all-btn-wrapper--> */}
              <div className='title-and-btn-wrapper'>
                {/* <!--title--> */}
                <h2 className='podcast-heading-secondary'>Latest Episodes</h2>
                {/* <!--btn--> */}
                <a href='#' className='view-all-btn'>
                  View All
                  {/* <FaArrowRight className='fa-solid fa-arrow-right view-all-btn-icon' /> */}
                </a>
              </div>
              {/* <!--boxes-wrapper--> */}
              <div className='podcast-boxes-wrapper' ref={shopRef}>
                {/* <!--box- 1 --> */}
                <div className='podcast-box'>
                  {/* <!--input-for-controling-the-functionality-of-box--> */}
                  <input
                    type='checkbox'
                    id='play-1'
                    className='podcast-box-input podcast-box-input-1'
                    hidden
                  />
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='podcast-text-and-play-button-wrapper'>
                    <label
                      for='play-1'
                      className='podcast-box-label podcast-play-btn'
                    >
                      <FaPlay className='fa-solid fa-play podcast-play-icon podcast-btn-icon' />
                      <FaPause className='fa-solid fa-pause podcast-pause-icon podcast-btn-icon' />
                    </label>
                    {/* <!--text--> */}
                    <p class='podcast-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img1} alt='img' className='podcast-box-img' />
                  <span className='podcast-box-overlay podcast-box-overlay-1'></span>
                </div>
                {/* <!--box - 2--> */}
                <div className='podcast-box'>
                  {/* <!--input-for-controling-the-functionality-of-box--> */}
                  <input
                    type='checkbox'
                    id='play-2'
                    className='podcast-box-input podcast-box-input-1'
                    hidden
                  />
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='podcast-text-and-play-button-wrapper'>
                    <label
                      for='play-2'
                      className='podcast-box-label podcast-play-btn'
                    >
                      <FaPlay className='fa-solid fa-play podcast-play-icon podcast-btn-icon' />
                      <FaPause className='fa-solid fa-pause podcast-pause-icon podcast-btn-icon' />
                    </label>
                    {/* <!--text--> */}
                    <p class='podcast-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img2} alt='img' className='podcast-box-img' />
                  <span className='podcast-box-overlay podcast-box-overlay-1'></span>
                </div>
                {/* <!--box - 3--> */}
                <div className='podcast-box'>
                  {/* <!--input-for-controling-the-functionality-of-box--> */}
                  <input
                    type='checkbox'
                    id='play-3'
                    className='podcast-box-input podcast-box-input-1'
                    hidden
                  />
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='podcast-text-and-play-button-wrapper'>
                    <label
                      for='play-3'
                      className='podcast-box-label podcast-play-btn'
                    >
                      <FaPlay className='fa-solid fa-play podcast-play-icon podcast-btn-icon' />
                      <FaPause className='fa-solid fa-pause podcast-pause-icon podcast-btn-icon' />
                    </label>
                    {/* <!--text--> */}
                    <p class='podcast-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img3} alt='img' className='podcast-box-img' />
                  <span className='podcast-box-overlay podcast-box-overlay-1'></span>
                </div>
                {/* <!--box - 4--> */}
                <div className='podcast-box'>
                  {/* <!--input-for-controling-the-functionality-of-box--> */}
                  <input
                    type='checkbox'
                    id='play-4'
                    className='podcast-box-input podcast-box-input-1'
                    hidden
                  />
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='podcast-text-and-play-button-wrapper'>
                    <label
                      for='play-4'
                      className='podcast-box-label podcast-play-btn'
                    >
                      <FaPlay className='fa-solid fa-play podcast-play-icon podcast-btn-icon' />
                      <FaPause className='fa-solid fa-pause podcast-pause-icon podcast-btn-icon' />
                    </label>
                    {/* <!--text--> */}
                    <p class='podcast-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img4} alt='img' className='podcast-box-img' />
                  <span className='podcast-box-overlay podcast-box-overlay-1'></span>
                </div>
              </div>
            </div>
            {/* <!--section-btns-------> */}

            <div className='next-perv-btn-container'>
              <span
                className='slider-btn perv-btn'
                onClick={() => scrollContainershop(shopRef, 'left')}
              >
                <span className='slider-btn-border perv-btn-border'></span>
                <FaArrowLeft className='fa-arrow-left slider-btn-icon' />
              </span>
              <span
                className='slider-btn next-btn'
                onClick={() => scrollContainershop(shopRef, 'right')}
              >
                <span className='slider-btn-border next-btn-border'></span>
                <FaArrowRight className=' slider-btn-icon' />
              </span>
            </div>
          </div>
        </section>
        {/* <!--===========article-banner-section============--> */}
        <section className='article-banner-section'>
          {/* <!--setting-container--> */}
          <div className='article-banner-container'>
            {/* <!--cotent-wrapper--> */}
            <div className='article-content-wrapper'>
              {/* <!--banner-box--> */}
              <div className='article-banner-box'>
                {/* <!--img--> */}
                <img src={img6} alt='img' className='article-banner' />
              </div>
              {/* <!--text-box--> */}
              <div className='article-text-box'>
                {/* <!--background-lights--> */}
                <div className='article-banner-background-light background-lights'></div>
                {/* <!--title--> */}
                <h3 className='article-heading-primary'>
                  Fresh Perspectives
                  <span className='article-heading-sub'>Read and Discover</span>
                </h3>
                {/* <!--pragraph--> */}
                <p className='article-pragraph'>
                  Explore a world of engaging stories, insightful interviews,
                  and expert discussions with our curated podcasts. Designed to
                  inspire, educate, and entertain, our selection offers fresh
                  perspectives on diverse topics.
                </p>
                {/* <!--btn--> */}
                <a href='#' className='article-btn'>
                  See All Articles <i className='fa-solid fa-arrow-right'></i>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* 
        <!--============latest-articles-section================--> */}
        <section className='latest-articles-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--latest-article-section--> */}
            <div className='latest-article-content-wrapper'>
              {/* <!--title-and-view-all-btn-wrapper--> */}
              <div className='title-and-btn-wrapper'>
                {/* <!--title--> */}
                <h2 className='article-heading-secondary'>Latest Articles</h2>
                {/* <!--btn--> */}
                <a href='#' className='view-all-btn'>
                  View All
                </a>
              </div>
              {/* <!--boxes-wrapper--> */}
              <div className='article-boxes-wrapper' ref={articleRef}>
                {/* <!--box--> */}
                <div className='article-box'>
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='article-text-and-play-button-wrapper'>
                    {/* <!--button--> */}
                    <a href='#' className='article-view-link'>
                      <FaEye className='fa-solid fa-eye article-view-link-icon' />
                    </a>
                    {/* <!--text--> */}
                    <p className='article-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img7} alt='img' className='article-box-img' />
                  <span className='article-box-overlay article-box-overlay-1'></span>
                </div>
                {/* <!--box--> */}
                <div className='article-box'>
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='article-text-and-play-button-wrapper'>
                    {/* <!--button--> */}
                    <a href='#' className='article-view-link'>
                      <FaEye className='fa-solid fa-eye article-view-link-icon' />
                    </a>
                    {/* <!--text--> */}
                    <p className='article-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img8} alt='img' className='article-box-img' />
                  <span className='article-box-overlay article-box-overlay-1'></span>
                </div>
                {/* <!--box--> */}
                <div className='article-box'>
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='article-text-and-play-button-wrapper'>
                    {/* <!--button--> */}
                    <a href='#' className='article-view-link'>
                      <FaEye className='fa-solid fa-eye article-view-link-icon' />
                    </a>
                    {/* <!--text--> */}
                    <p className='article-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img9} alt='img' className='article-box-img' />
                  <span className='article-box-overlay article-box-overlay-1'></span>
                </div>
                {/* <!--box--> */}
                <div className='article-box'>
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='article-text-and-play-button-wrapper'>
                    {/* <!--button--> */}
                    <a href='#' className='article-view-link'>
                      <FaEye className='fa-solid fa-eye article-view-link-icon' />
                    </a>
                    {/* <!--text--> */}
                    <p className='article-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img10} alt='img' className='article-box-img' />
                  <span className='article-box-overlay article-box-overlay-1'></span>
                </div>
                {/* <!--box--> */}
                <div className='article-box'>
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='article-text-and-play-button-wrapper'>
                    {/* <!--button--> */}
                    <a href='#' className='article-view-link'>
                      <FaEye className='fa-solid fa-eye article-view-link-icon' />
                    </a>
                    {/* <!--text--> */}
                    <p className='article-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img11} alt='img' className='article-box-img' />
                  <span className='article-box-overlay article-box-overlay-1'></span>
                </div>
                {/* <!--box--> */}
                <div className='article-box'>
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='article-text-and-play-button-wrapper'>
                    {/* <!--button--> */}
                    <a href='#' className='article-view-link'>
                      <FaEye className='fa-solid fa-eye article-view-link-icon' />
                    </a>
                    {/* <!--text--> */}
                    <p className='article-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img12} alt='img' className='article-box-img' />
                  <span className='article-box-overlay article-box-overlay-1'></span>
                </div>
                {/* <!--box--> */}
                <div className='article-box'>
                  {/* <!--date-and-episode-box--> */}
                  <div className='date-and-episode-box'>
                    {/* <!--date--> */}
                    <p className='date'>22 Apr 2024</p>
                    {/* <!--episode--> */}
                    <p className='episode'>Episode 01</p>
                  </div>
                  {/* <!--text-and-button-wrapper--> */}
                  <div className='article-text-and-play-button-wrapper'>
                    {/* <!--button--> */}
                    <a href='#' className='article-view-link'>
                      <FaEye className='fa-solid fa-eye article-view-link-icon' />
                    </a>
                    {/* <!--text--> */}
                    <p className='article-box-text'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Eaque ipsa autem ill
                    </p>
                  </div>
                  {/* <!--music-loader---> */}
                  <div className='music-loader-box'>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                    <span className='stroke'></span>
                  </div>
                  {/* <!--img--> */}
                  <img src={img13} alt='img' className='article-box-img' />
                  <span className='article-box-overlay article-box-overlay-1'></span>
                </div>
              </div>
            </div>
            {/* <!--section-btns-------> */}
            <div className='next-perv-btn-container'>
              <span
                className='slider-btn perv-btn'
                onClick={() => scrollContainerarticle(articleRef, 'left')}
              >
                <span className='slider-btn-border perv-btn-border'></span>
                <FaArrowLeft className='fa-arrow-left slider-btn-icon' />
              </span>
              <span
                className='slider-btn next-btn'
                onClick={() => scrollContainerarticle(articleRef, 'right')}
              >
                <span className='slider-btn-border next-btn-border'></span>
                <FaArrowRight className=' slider-btn-icon' />
              </span>
            </div>
          </div>
        </section>
      </main>
      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default PodcastArticlePage
