import { useState } from 'react'
import { useNFTDrop, useAddress, ConnectWallet } from '@thirdweb-dev/react'
import axios from 'axios'


const MintNFTPage = () => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState(null)
  const [fileUrl, setFileUrl] = useState('')

  // Pinata

  const contractAddress = '0xAb1a1c03BE678B5d55Cec01322f7DAC5a2Bc7aFC'

  const uploadToIPFS = async (file) => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`

    // ایجاد فرم دیتا
    let data = new FormData()
    data.append('file', file)

    // تنظیمات هدر برای احراز هویت
    const headers = {
      pinata_api_key: '62799e0ec743a397df72',
      pinata_secret_api_key:
        '5019a7abcd836cdd775af35f3798bfa56aedc55da0a4067b3cf2e525dc5e4f3b',
    }

    try {
      const response = await axios.post(url, data, {
        headers: headers,
        maxContentLength: 'Infinity', // این تنظیم برای فایل‌های بزرگ لازم است
        maxBodyLength: 'Infinity',
      })

      // برگرداندن URL IPFS
      return `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`
    } catch (error) {
      console.error('Error uploading file to IPFS:', error)
      throw new Error('Failed to upload to IPFS.')
    }
  }

  const address = useAddress() // دریافت آدرس کاربر از کیف پول متصل
  const nftDrop = useNFTDrop(contractAddress)

  const onFileChange = async (e) => {
    const file = e.target.files[0]
    const url = await uploadToIPFS(file) // فرض بر این است که تابع uploadToIPFS تعریف شده
    setFileUrl(url)
    setImage(file)
  }

  const mintNft = async () => {
    if (!name || !description || !fileUrl || !address) {
      alert('Please fill all fields, upload an image, and connect your wallet.')
      return
    }

    try {
      const metadata = {
        name: name,
        description: description,
        image: fileUrl,
      }

      const tx = await nftDrop.claimTo(address, 1, metadata)
      await tx.wait()
      alert('NFT successfully minted!')
    } catch (error) {
      console.error('Failed to mint NFT:', error)
      alert('Failed to mint NFT.')
    }
  }

  return (
    <div>
      {!address && (
        <ConnectWallet
          className='connect-button'
          theme='dark'
          showThirdwebBranding={false}
          btnTitle='Connect Wallet'
        />
      )}
      <input
        type='text'
        placeholder='Name'
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <textarea
        placeholder='Description'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <input type='file' onChange={onFileChange} />
      {image && <img src={URL.createObjectURL(image)} alt='NFT Preview' />}
      <button onClick={mintNft} disabled={!address}>
        Mint NFT
      </button>
    </div>
  )
}

export default MintNFTPage
