import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import '../styles/Recommendation.css'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  faMagnifyingGlass,
  faBagShopping,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import {
  faInstagram,
  faSquareTwitter,
  faSquareFacebook,
  faPinterest,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

// Image

import ShopPic1 from '../assets/images/nft-1.jpg'
import ShopPic2 from '../assets/images/nft-2.jpg'
import ShopPic3 from '../assets/images/nft-3.jpg'
import ShopPic4 from '../assets/images/shop-img.jpg'
import ShopPic5 from '../assets/images/nft-5.jpg'
import ShopPic6 from '../assets/images/nft-6.jpg'
import ShopPic7 from '../assets/images/nft-7.jpg'
import ShopPic8 from '../assets/images/nft-8.jpg'
import ShopPic9 from '../assets/images/nft-9.jpg'
import ShopPic10 from '../assets/images/nft-10.jpg'
import ShopPic11 from '../assets/images/nft-11.jpg'
import ShopPic12 from '../assets/images/nft-12.jpg'
import ShopPic13 from '../assets/images/nft-13.jpg'
import ShopPic14 from '../assets/images/nft-14.jpg'
import logo from '../assets/images/diption-logo-2.jpg'

// Products List

const PopularProducts = [
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic6,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic7,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic8,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic9,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic10,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic11,
  },
]

const TrendingProducts = [
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic12,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic13,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic14,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic1,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic2,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic2,
  },
]

const TopProducts = [
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic3,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic4,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic5,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic6,
  },
  {
    name: '3D holographic',
    price: '0.8 ETH',
    creator: 'Trevor Jones',
    image: ShopPic7,
  },
]

function RecommendationPage() {
  // navigate
  let navigate = useNavigate()

  const handleProfileIcon = () => {
    navigate('/login')
  }

  // Option
  const [activeOption, setActiveOption] = useState('top-section')

  const handleOptionClick = (option) => {
    setActiveOption(option)
  }

  let productsToDisplay
  switch (activeOption) {
    case 'popular':
      productsToDisplay = PopularProducts
      break
    case 'trending':
      productsToDisplay = TrendingProducts
      break
    case 'top-section':
    default:
      productsToDisplay = TopProducts
      break
  }

  // DropDown
  // -- Art --
  const [isOpenArt, setIsOpenArt] = useState(false)
  const toggleDropdownArt = () => setIsOpenArt(!isOpenArt)

  return (
    <div>
      {/* Navbar */}
      <section className='header-top-section'>
        <div className='container'>
          <nav className='navigation'>
            <div className='nav-left-wrapper'>
              <a href='/' className='logo-link'>
                <img className='logo-home' src={logo} alt='logo-img' />
              </a>
            </div>
            <div className='nav-right-wrapper'>
              <div className='nav-list-wrapper'>
                <ul className='menu'>
                  <li>
                    <a href='/create'>Create</a>
                  </li>
                  <li>
                    <a href='/podcast-article'>Product & Articles</a>
                  </li>
                  <li>
                    <a href='/about-us'>About us</a>
                  </li>
                </ul>
              </div>
              <div className='nav-search-and-link-wrapper'>
                <form action='#' className='search-wrapper'>
                  <button type='submit' className='search-btn'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                  <input
                    type='search'
                    className='search-input'
                    placeholder='Search'
                  />
                </form>
                <Link to='/shop' className='shop-btn nav-link-btns'>
                  <FontAwesomeIcon icon={faBagShopping} />
                </Link>
                <div className='dropdown-btn-profile'>
                  <div className='select-profile' onClick={handleProfileIcon}>
                    <FontAwesomeIcon icon={faUser} className='profile-icon' />
                  </div>
                  <ul className='drop-menu-profile'>
                    <li>
                      <a href='#'>Login</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Favorite</a>
                    </li>
                    <li>
                      <a href='#'>Diption pro</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                    <li>
                      <a href='#'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      {/* Title Section */}
      <section className='recommendation-title-section'>
        {/* <!--setting-recommendation-container---------> */}
        <div className='title-recommendation-container recommendation-container'>
          {/* <!--title------> */}
          <h1 className='recommendation-title'>Suggestion</h1>
        </div>
      </section>
      {/* <!--=recommendation-options-section(Top-Popular-Trending)==--> */}
      <section class='recommendation-options-and-button-section'>
        {/* <!--setting-container-----------> */}
        <div className='options-and-button-recommendation-section-container recommendation-container'>
          {/* <!--recommendation-option-and-button-wrapper-------> */}
          <div className='recommendation-option-and-button-wrapper'>
            {/* <!--recommendation-option-content-wrapper-------------> */}
            <div className='recommendation-option-content-wrapper'>
              {/* <!--recommendation-options--> */}
              <div className='recommendation-options-wrapper'>
                {/* <!--option-button-----> */}
                <button
                  className={`recommendation-option-button top-section-btn ${
                    activeOption === 'top-section' ? 'active' : ''
                  }`}
                  onClick={() => handleOptionClick('top-section')}
                >
                  Top Artworks
                </button>
                {/* <!--option-button-----> */}
                <button
                  className={`recommendation-option-button popular-section-btn ${
                    activeOption === 'popular' ? 'active' : ''
                  }`}
                  onClick={() => handleOptionClick('popular')}
                >
                  Popular
                </button>
                {/* <!--option-button-----> */}
                <button
                  className={`recommendation-option-button trending-section-btn ${
                    activeOption === 'trending' ? 'active' : ''
                  }`}
                  onClick={() => handleOptionClick('trending')}
                >
                  Trending
                </button>

                {/* <!--active-box-------> */}
                <div className='active-box active-box-start-top-section'></div>
              </div>
            </div>
            {/* <!--recommendation-sort-button----> */}
            {/* <!--dorop-botton------> */}
            <div className='custom-dropdown'>
              <div className='dropdown-header' onClick={toggleDropdownArt}>
                <span className='selected'>Art Style</span>
                {isOpenArt ? <FaChevronUp /> : <FaChevronDown />}
                <i class='fa-solid fa-chevron-down cart'></i>
              </div>
              {isOpenArt && (
                <ul className='dropdown-list'>
                  {/* <!--item--> */}
                  <li>Modern</li>
                  {/* <!--item--> */}
                  <li>Impressionism</li>
                  {/* <!--item--> */}
                  <li>Surrealism</li>
                  {/* <!--active-item---> */}
                  <li>Photography</li>
                  {/* <!--item--> */}
                  <li>Digital Art</li>
                  {/* <!--item--> */}
                  <li>Calligraphy</li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-Recommendation-main-section-include(Top,popular,trending->*/}
      <section className='recommendation-main-sections-wrapper'>
        <div className='main-section-recommendation-container recommendation-container'>
          {/* <!--background-light--------------------> */}
          <div className='recommendation-main-section-background-light background-lights'></div>
          {/*<!--===========top-section=======================--> */}
          <section className='recommendation-top-section recommendation-sections'>
            {/* <!---=============box-wrapper=====================--> */}
            <div className='product-box-container'>
              {productsToDisplay.map((product, index) => (
                <a key={index} href='/' className='product-link'>
                  <div className='product-box'>
                    <div className='img-wrapper'>
                      <img src={product.image} alt={product.name} />
                    </div>
                    <div className='caption'>
                      <div className='caption-title-and-price-wrapper'>
                        <strong>{product.name}</strong>
                        <span>{product.price}</span>
                      </div>
                      <div className='caption-creator-and-view-button-wrapper'>
                        <span>{product.creator}</span>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </section>
        </div>
      </section>
      {/* ---------- main section end------------ */}
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='container'>
            <div className='container'>
              <div className='footer-content-wrapper'>
                <div className='footer-left-wrapper'>
                  <strong className='footer-title'> DIPTION </strong>
                  <p className='footer-text-wrapper'>
                    The world's largest digital marketplace for crypto
                    collectibles and non-fungible tokens (NFTs). Buy,sell,and
                    discover exclusive digital items.
                  </p>
                  <div className='social-links-wrapper'>
                    <a href='https://instagram.com'>
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://twitter.com'>
                      <FontAwesomeIcon icon={faSquareTwitter} />
                    </a>
                    <a href='https://facebook.com'>
                      <FontAwesomeIcon icon={faSquareFacebook} />
                    </a>
                    <a href='https://pinterest.com'>
                      <FontAwesomeIcon icon={faPinterest} />{' '}
                      {/* اضافه شده Pinterest */}
                    </a>
                    <a href='https://telegram.org'>
                      <FontAwesomeIcon icon={faTelegram} />{' '}
                      {/* اضافه شده Telegram */}
                    </a>
                  </div>
                </div>
                <div className='footer-right-wrapper'>
                  <ul className='explore-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Art style</a>
                    </li>
                    <li>
                      <a href='#'>Abstract</a>
                    </li>
                    <li>
                      <a href='#'>Landscape</a>
                    </li>
                    <li>
                      <a href='#'>Animal</a>
                    </li>
                    <li>
                      <a href='#'>Face and portrait</a>
                    </li>
                    <li>
                      <a href='#'>Figurative</a>
                    </li>
                  </ul>

                  <ul className='my-account-footer-list'>
                    <li className='footer-ul-title'>My Account</li>
                    <li>
                      <a href='#'>Diption pto</a>
                    </li>
                    <li>
                      <a href='#'>History</a>
                    </li>
                    <li>
                      <a href='#'>Saved</a>
                    </li>
                    <li>
                      <a href='#'>Wallet</a>
                    </li>
                    <li>
                      <a href='#'>Profile</a>
                    </li>
                  </ul>

                  <ul className='resource-footer-list'>
                    <li className='footer-ul-title'>Resource</li>
                    <li>
                      <a href='#'>Podcasts</a>
                    </li>
                    <li>
                      <a href='#'>Articles</a>
                    </li>
                    <li>
                      <a href='#'>Help center</a>
                    </li>
                    <li>
                      <a href='#'>Learn</a>
                    </li>
                  </ul>

                  <ul className='company-footer-list'>
                    <li className='footer-ul-title'>Company</li>
                    <li>
                      <a href='/about-us'>About us</a>
                    </li>
                    <li>
                      <a href='/contact-us'>Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default RecommendationPage
