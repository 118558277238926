import React, { useState, useRef } from 'react'
import '../styles/CreateCollection.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { FaArrowLeft, FaEthereum } from 'react-icons/fa'
import { FaPlus } from 'react-icons/fa6'
import { SiPolygon } from 'react-icons/si'
import { IoIosMore } from 'react-icons/io'

// History Cart

function CreateCollectionPage() {
  return (
    <div className='container'>
      <div className='top-background-light background-lights'></div>
      <section className='create-collection-section'>
        {/* <!--btn--> */}
        <div className='back-btn'>
          <a href='/create-nft' className='back-btn-link'>
            <FaArrowLeft className='fa-solid fa-arrow-left back-btn-icon' />
          </a>
        </div>
        {/* <!--main-title--> */}
        <div className='main-title-wrapper'>
          <h1 className='create-collection-heading-primary'>
            First, you'll need to create a collection for your NFT
          </h1>
          <p className='header-text'>
            Lorem ipsum is a placeholder text commonly used to demonstrate the
            visual form of a document
          </p>
        </div>
        <div className='create-collection-content-wrapper'>
          <form action='#' className='create-collectino-form'>
            {/* <!--box-title--> */}
            <h3 className='create-collection-box-title'>Logo image</h3>
            {/* <!--upload-logo--> */}
            <div className='upload-img-box'>
              {/* <!--plus-box--> */}
              <div className='upload-shape-box'>
                <FaPlus className='fa-solid fa-plus create-collection-shap-icon' />
              </div>
              {/* <!--text-and-upload-button-wrapper--> */}
              <div className='text-and-upload-button-wrapper'>
                {/* <!--upload-btn--> */}
                <input
                  type='file'
                  hidden
                  id='upload-input'
                  className='create-collection-upload-input'
                />
                <label
                  for='upload-input'
                  className='create-collection-upload-label'
                >
                  Click to upload
                </label>
                {/* <!--size-text--> */}
                <p className='create-collection-upload-text create-collection-upload-size-text'>
                  Recommended size: 350 x 350
                </p>
                {/* <!--type-text--> */}
                <p className='create-collection-upload-text create-collection-upload-type-text'>
                  File types: JPG,PNG,SVG
                </p>
              </div>
            </div>
            {/* <!--Collection-name--> */}
            {/* <!--title--> */}
            <h3 className='create-collection-box-title'>Collection name</h3>
            <div className='create-collection-input-wrapper'>
              {/* <!--input--> */}
              <input
                className='create-collection-input'
                type='text'
                name='name'
                placeholder='Name your Collection'
              />
            </div>
            {/* <!--Block-chain--> */}
            {/* <!--title--> */}
            <h3 className='create-collection-box-title'>Blockchain</h3>
            <div className='block-chain-wrapper'>
              {/* <!--btn--> */}
              <button className='block-chain-btn'>
                {/* <!--icon-and-title--> */}
                <div className='block-chain-icon-title-wrapper'>
                  {/* <!--icon-wrpper--> */}
                  <div className='block-chain-icon-wrapper'>
                    {/* <!--icon--> */}
                    <FaEthereum className='fa-brands fa-ethereum block-chain-icon' />
                  </div>
                  {/* <!--title--> */}
                  <h4 className='block-chain-title'>Ethereum</h4>
                </div>
                {/* <!--text--> */}
                <p className='block-chain-text'>
                  Estimated cost to deploy contract: $54.08
                </p>
                <span className='box-background'></span>
              </button>
              {/* <!--btn--> */}
              <button className='block-chain-btn'>
                {/* <!--icon-and-title--> */}
                <div className='block-chain-icon-title-wrapper'>
                  {/* <!--icon-wrpper--> */}
                  <div className='block-chain-icon-wrapper'>
                    {/* <!--icon--> */}
                    <SiPolygon className='fa-brands fa-ethereum block-chain-icon' />
                  </div>
                  {/* <!--title--> */}
                  <h4 className='block-chain-title'>Polygon</h4>
                </div>
                {/* <!--text--> */}
                <p className='block-chain-text'>
                  Estimated cost to deploy contract: $0.08
                </p>
                <span className='box-background'></span>
              </button>
              {/* <!--btn--> */}
              <button className='block-chain-btn see-more-btn'>
                {/* <!--icon-and-title--> */}
                <div className='block-chain-icon-title-wrapper see-more-btn-icon-title-wrapper'>
                  {/* <!--icon-wrpper--> */}
                  <div className='block-chain-icon-wrapper'>
                    {/* <!--icon--> */}
                    <IoIosMore className='fa-solid fa-ellipsis-vertical block-chain-icon' />
                  </div>
                  {/* <!--title--> */}
                  <h4 className='block-chain-title'>See more</h4>
                </div>
                <span className='box-background'></span>
              </button>
            </div>
            <div className='create-btn-wrapper'>
              {/* <!--create-btn--> */}
              <button type='submit' className='create-btn'>
                Create
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  )
}

export default CreateCollectionPage
